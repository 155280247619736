import classNames from 'classnames';
import { FunctionComponent, MouseEvent } from 'react';
import { HiChevronRight } from 'react-icons/hi';
import { PagerClass } from './Pagination';

export type NextProps = {
  currentPage: number;
  pageCount: number;
  pagerClass: PagerClass;
  onNext: (e: MouseEvent) => void;
};

export const Next: FunctionComponent<NextProps> = ({ currentPage, pageCount, pagerClass, onNext }) => {
  const disabled = currentPage === pageCount || pageCount === 0;

  const onNextClick = (e: MouseEvent) => {
    e.preventDefault();
    if (disabled) {
      return;
    }
    onNext(e);
  };

  const pagerNextClass = classNames(
    pagerClass.default,
    'pagination-pager-next',
    disabled ? pagerClass.disabled : pagerClass.inactive,
  );

  return (
    <span className={pagerNextClass} onClick={onNextClick}>
      <HiChevronRight />
    </span>
  );
};
