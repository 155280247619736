import { useRef } from 'react';
import uniqueId from 'lodash/uniqueId';

export const useUniqueId = (prefix: string) => {
  const idRef = useRef<string>();

  if (!idRef.current) {
    idRef.current = `${uniqueId(prefix)}`;
  }

  return idRef.current;
};
