import classNames from 'classnames';
import { ElementType, forwardRef, MouseEvent, PropsWithChildren } from 'react';

export type TdProps = {
  className?: string;
  asElement?: ElementType;
  onClick?: () => void;
  onContextMenu?: (e: MouseEvent<HTMLTableCellElement>) => void;
};

export const Td = forwardRef<HTMLElement, PropsWithChildren<TdProps>>(
  ({ children, className, asElement: Component = 'td', ...otherProps }, ref) => {
    const tdClass = classNames(Component !== 'td' && 'td', className);

    return (
      <Component className={tdClass} ref={ref} {...otherProps}>
        {children}
      </Component>
    );
  },
);
