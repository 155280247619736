// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#preventing-multiple-unauthorized-errors
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type { BaseQueryApi, BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import jwt_decode from 'jwt-decode';
// import { tokenReceived, loggedOut } from './authSlice';
import { Mutex } from 'async-mutex';
import { paramsSerializer } from './paramsSerializer';
import { MERCURE_BASE_URL, MOBYSTOCK_API_BASE_URL } from 'src/config/env';
import { RootState } from 'src/store';
import { MERCURE_TOKEN_COOKIE, TOKENS_COOKIE } from 'src/config/cookies';
import { DecodedToken, Tokens } from '../mobystockAuth';

// create a new mutex
const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
  baseUrl: MOBYSTOCK_API_BASE_URL,
  paramsSerializer,
  prepareHeaders: (headers, { getState }) => {
    if (!headers.has('Content-Type')) {
      headers.set('Content-Type', 'application/ld+json');
    }
    headers.set('Accept', 'application/ld+json, application/x-www-form-urlencoded');
    const tokens = (getState() as RootState).auth.tokens;
    if (tokens) {
      headers.set('Authorization', `Bearer ${tokens.token}`);
    }
    return headers;
  },
});

const tokens_str = localStorage.getItem(TOKENS_COOKIE);
const tokens = tokens_str ? JSON.parse(tokens_str) : null;

const refreshQuery = fetchBaseQuery({
  baseUrl: MOBYSTOCK_API_BASE_URL,
  method: 'POST',
  body: JSON.stringify({ refresh_token: tokens?.refresh_token }),
  paramsSerializer,
  prepareHeaders: (headers, { getState }) => {
    headers.set('Content-Type', 'application/ld+json');
    headers.set('Accept', 'application/ld+json, application/x-www-form-urlencoded');
    const tokens = (getState() as RootState).auth.tokens;
    if (tokens) {
      headers.set('Authorization', `Bearer ${tokens.token}`);
    }
    return headers;
  },
});

const tokenRefresh = async (api: BaseQueryApi, extraOptions: any) => {
  const refreshResult = await refreshQuery('/token/refresh', api, extraOptions);
  if (refreshResult.data) {
    const refreshed = refreshResult.data as Tokens;
    const decodedToken: DecodedToken = jwt_decode(refreshed.token);
    localStorage.setItem(TOKENS_COOKIE, JSON.stringify(refreshed));
    localStorage.setItem(MERCURE_TOKEN_COOKIE, decodedToken.mercure_token);
    // force reload in any case, if token is valid then the view will reload, if not it will fall on login
    window.location.reload();
  }
};

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    type ResponseData = { message: string };
    const data = result.error.data as ResponseData;
    if (data.message === 'Expired JWT Token') {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          tokenRefresh(api, extraOptions);
        } finally {
          // release must be called once the mutex should be released again.
          release();
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    }
  }
  return result;
};

// MERCURE

const mercureQuery = fetchBaseQuery({
  baseUrl: MERCURE_BASE_URL,
  paramsSerializer,
  prepareHeaders: (headers) => {
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    const mercure_token = localStorage.getItem(MERCURE_TOKEN_COOKIE);
    if (mercure_token) {
      headers.set('Authorization', `Bearer ${mercure_token}`);
    }
    return headers;
  },
});

export const baseQueryMercure: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await mercureQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    type ResponseData = { message: string };
    const data = result.error.data as ResponseData;
    if (data.message === 'Expired JWT Token') {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          tokenRefresh(api, extraOptions);
        } finally {
          // release must be called once the mutex should be released again.
          release();
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    }
  }
  return result;
};
