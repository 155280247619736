import * as Sentry from '@sentry/react';
import { FunctionComponent } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { IS_PROD } from 'src/config/env';
import { PublicLayout } from 'src/layouts';
import { Error } from 'src/views/Error';
import { LogIn } from 'src/views/auth/LogIn';

export const PublicNavigator: FunctionComponent = () => {
  const routes = (
    <Routes>
      <Route index element={<Navigate to="/login" replace />} />
      <Route path="/login" element={<LogIn />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );

  return IS_PROD ? (
    <PublicLayout>
      <Sentry.ErrorBoundary
        fallback={(props) => <Error {...props} />}
        beforeCapture={(scope) => {
          scope.setTag('navigator', 'public');
        }}
      >
        {routes}
      </Sentry.ErrorBoundary>
    </PublicLayout>
  ) : (
    <PublicLayout>{routes}</PublicLayout>
  );
};
