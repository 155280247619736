import { ElementType, FunctionComponent, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { setCurrentRouteKey, setLayout, setPreviousLayout } from 'src/store';
import { ValueOf } from 'src/types';
import { LAYOUT_ENUM } from 'src/config/theme';
import { RouteCustomObject } from 'src/config/navigation/routes';

export type AppRouteProps = {
  component?: ElementType;
  routeKey?: RouteCustomObject['key'];
  header?: string;
  layout?: ValueOf<typeof LAYOUT_ENUM>;
};

export const AppRoute: FunctionComponent<AppRouteProps> = ({
  component: Component = 'div',
  routeKey,
  header,
  ...props
}) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const layoutType = useAppSelector((state) => state.theme.layout.type);
  const previousLayout = useAppSelector((state) => state.theme.layout.previousType);

  const handleLayoutChange = useCallback(() => {
    dispatch(setCurrentRouteKey(routeKey));

    if (props.layout && props.layout !== layoutType) {
      dispatch(setPreviousLayout(layoutType));
      dispatch(setLayout(props.layout));
    }

    if (!props.layout && previousLayout && layoutType !== previousLayout) {
      dispatch(setLayout(previousLayout));
      dispatch(setPreviousLayout(''));
    }
  }, [dispatch, layoutType, previousLayout, props.layout, routeKey]);

  useEffect(() => {
    handleLayoutChange();
  }, [location, handleLayoutChange]);

  return <Component {...props} />;
};
