import { useState, useEffect, useRef, forwardRef, ReactNode, PropsWithChildren, StyleHTMLAttributes } from 'react';
import classNames from 'classnames';
import { useMergedRef } from 'src/hooks';

export type AvatarProps = {
  size?: 'sm' | 'md' | 'lg' | number;
  src?: string;
  srcSet?: string;
  shape?: 'rounded' | 'square' | 'circle';
  alt?: string;
  className?: string;
  icon?: ReactNode;
  style?: StyleHTMLAttributes<HTMLSpanElement>;
  onClick?: () => void;
};

export const Avatar = forwardRef<HTMLSpanElement, PropsWithChildren<AvatarProps>>(
  ({ size = 'md', src, srcSet, shape = 'rounded', alt, className, icon, children, style, ...otherProps }, ref) => {
    const [scale, setScale] = useState(1);

    const avatarChildren = useRef<HTMLElement>(null);
    const avatarNode = useRef<HTMLElement>(null);

    const avatarMergeRef = useMergedRef(ref, avatarNode);

    const innerScale = () => {
      if (!avatarChildren.current || !avatarNode.current) {
        return;
      }
      const avatarChildrenWidth = avatarChildren.current.offsetWidth;
      const avatarNodeWidth = avatarNode.current.offsetWidth;
      if (avatarChildrenWidth === 0 || avatarNodeWidth === 0) {
        return;
      }
      setScale(avatarNodeWidth - 8 < avatarChildrenWidth ? (avatarNodeWidth - 8) / avatarChildrenWidth : 1);
    };

    useEffect(() => {
      innerScale();
    }, [scale, children]);

    const sizeStyle =
      typeof size === 'number'
        ? {
            width: size,
            height: size,
            minWidth: size,
            lineHeight: `${size}px`,
            fontSize: icon ? size / 2 : 12,
          }
        : {};

    const classes = classNames(
      'avatar',
      `avatar-${shape}`,
      typeof size === 'string' ? `avatar-${size}` : '',
      className,
    );

    if (src) {
      children = <img className={`avatar-img avatar-${shape}`} src={src} srcSet={srcSet} alt={alt} loading="lazy" />;
    } else if (icon) {
      children = <span className={classNames('avatar-icon', `avatar-icon-${size}`)}>{icon}</span>;
    } else {
      const childrenSizeStyle = typeof size === 'number' ? { lineHeight: `${size}px` } : {};
      const stringCentralized = {
        transform: `translateX(-50%) scale(${scale})`,
      };
      children = (
        <span
          className={`avatar-string ${typeof size === 'number' ? '' : `avatar-inner-${size}`}`}
          style={{
            ...childrenSizeStyle,
            ...stringCentralized,
            ...(typeof size === 'number' ? { height: size } : {}),
          }}
          ref={(node) => {
            // @ts-expect-error
            avatarChildren.current = node;
          }}
        >
          {children}
        </span>
      );
    }

    return (
      <span className={classes} style={{ ...sizeStyle, ...style }} ref={avatarMergeRef} {...otherProps}>
        {children}
      </span>
    );
  },
);
