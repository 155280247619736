import { LazyExoticComponent, lazy } from 'react';

export type RouteCustomObject = {
  key:
    | 'pages'
    | 'clients'
    | 'itinerants'
    | 'workorders'
    | 'planning'
    | 'calls'
    | 'stocks'
    | 'products'
    | 'productsmanagement'
    | 'productcontainersmanagement'
    | 'suppliers'
    | 'admin'
    | 'preferences'
    | 'wip';
  path:
    | ''
    | '/clients'
    | '/itinerants'
    | '/workorders'
    | '/planning'
    | '/calls'
    | '/stocks'
    | '/products'
    | '/productsmanagement'
    | '/productcontainersmanagement'
    | '/suppliers'
    | '/admin'
    | '/wip';
  component: LazyExoticComponent<any>;
  meta?: Record<string, any>;
};

export const pagesRoutes: RouteCustomObject[] = [
  {
    key: 'clients',
    path: '/clients',
    component: lazy(() => import('src/views/clients/Clients')),
    meta: {
      header: 'Clients',
    },
  },
  {
    key: 'itinerants',
    path: '/itinerants',
    component: lazy(() => import('src/views/wip/Wip')),
    meta: {
      header: 'Itinerants',
    },
  },
  {
    key: 'workorders',
    path: '/workorders',
    component: lazy(() => import('src/views/planninggroup/workorders/WorkOrders')),
    meta: {
      header: 'Workorders',
    },
  },
  {
    key: 'planning',
    path: '/planning',
    component: lazy(() => import('src/views/planninggroup/planning/Planning')),
    meta: {
      header: 'Planning',
    },
  },
  {
    key: 'calls',
    path: '/calls',
    component: lazy(() => import('src/views/calls/Calls')),
    meta: {
      header: 'Calls',
    },
  },
  {
    key: 'stocks',
    path: '/stocks',
    component: lazy(() => import('src/views/stocks/Stocks')),
    meta: {
      header: 'Stocks',
    },
  },
  {
    key: 'productsmanagement',
    path: '/productsmanagement',
    component: lazy(() => import('src/views/products/productsmanagement/ProductsManagement')),
    meta: { header: 'ProductsManagement' },
  },
  {
    key: 'productcontainersmanagement',
    path: '/productcontainersmanagement',
    component: lazy(() => import('src/views/wip/Wip')),
    meta: { header: 'ProductContainersManagement' },
  },
  {
    key: 'suppliers',
    path: '/suppliers',
    component: lazy(() => import('src/views/wip/Wip')),
    meta: {
      header: 'Suppliers',
    },
  },
  {
    key: 'admin',
    path: '/admin',
    component: lazy(() => import('src/views/admin/Admin')),
    meta: {
      header: 'Admin',
    },
  },
  {
    key: 'wip',
    path: '/wip',
    component: lazy(() => import('src/views/wip/Wip')),
    meta: {
      header: 'Wip',
    },
  },
];
