import { createSlice } from '@reduxjs/toolkit';
import { Client, ClientEquipment } from 'src/types';

type ClientStateType = {
  drawerOpen: boolean;
  equipmentDrawerOpen: boolean;
  selectedClient: Client | null;
  selectedClientEquipment: ClientEquipment | null;
};

const initialState: ClientStateType = {
  drawerOpen: false,
  equipmentDrawerOpen: false,
  selectedClient: null,
  selectedClientEquipment: null,
};

const clientStateSlice = createSlice({
  name: 'crmClients/state',
  initialState,
  reducers: {
    setSelectedClient: (state, action) => {
      state.selectedClient = action.payload;
    },
    setSelectedClientEquipment: (state, action) => {
      state.selectedClientEquipment = action.payload;
    },
    setDrawerOpen: (state) => {
      state.drawerOpen = true;
    },
    setDrawerClose: (state) => {
      state.drawerOpen = false;
    },
    setEquipmentDrawerOpen: (state) => {
      state.equipmentDrawerOpen = true;
    },
    setEquipmentDrawerClose: (state) => {
      state.equipmentDrawerOpen = false;
    },
  },
});

export const {
  setSelectedClient,
  setSelectedClientEquipment,
  setDrawerOpen,
  setDrawerClose,
  setEquipmentDrawerOpen,
  setEquipmentDrawerClose,
} = clientStateSlice.actions;

export default clientStateSlice.reducer;
