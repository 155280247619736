import { createSlice } from '@reduxjs/toolkit';
import { Content } from 'src/types';

type StockContentStateType = {
  drawerOpen: boolean;
  selectedStockContent: Content | null;
};

const initialState: StockContentStateType = {
  drawerOpen: false,
  selectedStockContent: null,
};

const workOrderStateSlice = createSlice({
  name: 'stockContents/state',
  initialState,
  reducers: {
    setSelectedStockContent: (state, action) => {
      state.selectedStockContent = action.payload;
    },
    setDrawerOpen: (state) => {
      state.drawerOpen = true;
    },
    setDrawerClose: (state) => {
      state.drawerOpen = false;
    },
  },
});

export const { setSelectedStockContent, setDrawerOpen, setDrawerClose } = workOrderStateSlice.actions;

export default workOrderStateSlice.reducer;
