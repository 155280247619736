export const chainedFunction = (...funcs: Array<undefined | ((args: any) => void)>) => {
  return funcs
    .filter((f) => f !== null && typeof f !== 'undefined')
    .reduce((acc, f) => {
      if (typeof f !== 'function') {
        throw new Error('Argument only accept functions, undefined, or null.');
      }

      if (acc === undefined) {
        return f;
      }

      return function chainedFunction(...args) {
        //@ts-expect-error
        acc?.apply(this, args);
        //@ts-expect-error
        f.apply(this, args);
      };
    }, undefined);
};
