import classNames from 'classnames';
import { ElementType, StyleHTMLAttributes, forwardRef } from 'react';
import { IconBaseProps } from 'react-icons';
import { CgSpinner } from 'react-icons/cg';
import { useConfig } from '../ConfigProvider';

export type SpinnerProps = {
  className?: string;
  color?: string;
  enableTheme?: boolean;
  indicator?: ElementType;
  isSpining?: boolean;
  size?: string | number;
  style?: StyleHTMLAttributes<ElementType>;
};

export const Spinner = forwardRef<IconBaseProps, SpinnerProps>(
  (
    {
      className,
      color,
      enableTheme = true,
      indicator: Component = CgSpinner,
      isSpining = true,
      size = 20,
      style,
      ...rest
    },
    ref,
  ) => {
    const { themeColor, primaryColorLevel } = useConfig();

    const spinnerColor = color || (enableTheme && `${themeColor}-${primaryColorLevel}`);

    const spinnerStyle = {
      height: size,
      width: size,
      ...style,
    };

    const spinnerClass = classNames(isSpining && 'animate-spin', spinnerColor && `text-${spinnerColor}`, className);

    return <Component ref={ref} style={spinnerStyle} className={spinnerClass} {...rest} />;
  },
);
