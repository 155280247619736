import classNames from 'classnames';
import { motion } from 'framer-motion';
import { FunctionComponent, PropsWithChildren } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { useConfig } from '../ConfigProvider';

export type ExpanderProps = { expanded: boolean; onClick: (event: unknown) => void; className?: string };

export const Expander: FunctionComponent<PropsWithChildren<ExpanderProps>> = ({
  expanded,
  onClick,
  children,
  className,
}) => {
  const { themeColor, primaryColorLevel } = useConfig();

  const color = `text-${themeColor}-${primaryColorLevel}`;

  const expanderItemClass = classNames('flex point items-center cursor-pointer select-none gap-2', className);

  return (
    <div className={expanderItemClass} onClick={onClick}>
      <motion.span
        className="mt-1 text-lg"
        initial={{ transform: 'rotate(0deg)' }}
        animate={{
          transform: expanded ? 'rotate(-180deg)' : 'rotate(0deg)',
        }}
        transition={{ duration: 0.15 }}
      >
        <HiChevronDown className={color} />
      </motion.span>
      <span className="flex items-center">{children}</span>
    </div>
  );
};
