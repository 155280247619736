import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import dayjs, { Dayjs } from 'dayjs';

export const splitDateAndTime = (dateString: string, options?: { withoutSeconds?: boolean }) => {
  if (!dateString) {
    return { day: '', time: '' };
  }
  // the backend provides time in UTC timezone, convert it into the client's locale timezone, fixed for the moment
  const day = format(parseISO(dateString), 'yyyy-MM-dd', { locale: fr });
  const timeFormat = options?.withoutSeconds ? 'HH:mm' : 'HH:mm:ss';
  const time = format(parseISO(dateString), timeFormat, { locale: fr });

  return { day, time };
};

export const stripSeconds = (timeString: string) => {
  return timeString.slice(0, -3);
};

export const applyDeltaDaysToUTC = (timeString: string, deltaDays: number) => {
  const dateTime = new Date(timeString);
  dateTime.setDate(dateTime.getDate() + deltaDays);
  return convertTimezoneToUTC(dateTime.toISOString());
};

// Used only in the planning view : hack for displaying an event on multiple cells if it spans over multiple days
export const calculateEndDateAndTime = (date: string | null) => {
  if (!date) {
    return { day: undefined, time: undefined };
  }
  // it might be necessary to convert date into locale timezone to avoid wrongly switching endDate to a day before or after if kept UTC
  // anyway left as is for now
  // time is forced at end of the day to ensure showing a full day of work in calendar
  return {
    day: splitDateAndTime(dayjs(date).add(1, 'day').toISOString()).day,
    time: '18:00',
  };
};

export const convertTimezoneToUTC = (date: string | Dayjs | null): string => {
  // const convertedDate = format(parseISO(date), 'yyyy-MM-dd HH:mm', { locale: fr });
  // const convertedDate = dayjs.utc(date).format('YYYY-MM-DDTHH:mm:ssZ');
  const convertedDate = dayjs.utc(date).format();
  // const convertedDate = dayjs.utc(date).format('YYYY-MM-DDTHH:mm:ss');

  return convertedDate;
};

export const formatDate = (date: string, options?: { dateFormat: string }) => {
  if (date) {
    if (options?.dateFormat) {
      return format(new Date(date), options?.dateFormat, { locale: fr });
    }
    return format(new Date(date), 'dd MMMM yyyy', { locale: fr });
  }
};

export const convertTimezoneToLocale = (inputString: string | Dayjs | null): string => {
  // const dateWithTimezone = new Date(inputString);
  // const dateInUTC = new Date(dateWithTimezone.getTime() - dateWithTimezone.getTimezoneOffset() * 60000);
  // return dateInUTC.toISOString();
  return dayjs(inputString).format();
};
