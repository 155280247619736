import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { ElementType, HTMLAttributes, ReactNode, forwardRef } from 'react';
import { useConfig } from '../../ConfigProvider';
import { CONTROL_SIZES, LAYOUT, Layout, Size } from '../../utils/constant';
import { useForm } from '../context';
import { FormikErrors, FormikTouched } from 'formik';

export type FormItemProps = {
  children: ReactNode;
  label: string;
  labelClass?: string;
  errorMessage?: FormikErrors<string>;
  invalid?: boolean | FormikTouched<unknown>;
  className?: string;
  layout?: Layout;
  labelWidth?: string | number;
  asterisk?: boolean;
  style?: HTMLAttributes<ElementType>;
  size?: Size;
  extra?: string | ReactNode;
  htmlFor?: string;
};

export const FormItem = forwardRef<HTMLDivElement, FormItemProps>(
  (
    {
      children,
      label,
      labelClass,
      errorMessage,
      invalid,
      className,
      layout,
      labelWidth,
      asterisk,
      style,
      size,
      extra,
      htmlFor,
    },
    ref,
  ) => {
    const formContext = useForm();
    const { controlSize } = useConfig();

    const formItemLabelHeight = size || formContext.size || controlSize;
    const formItemLabelWidth = labelWidth || formContext.labelWidth;
    const formItemLayout = layout || formContext.layout;

    const getFormLabelLayoutClass = () => {
      switch (formItemLayout) {
        case LAYOUT.HORIZONTAL:
          return label
            ? `h-${CONTROL_SIZES[formItemLabelHeight]} ${label && 'ltr:pr-2 rtl:pl-2'}`
            : 'ltr:pr-2 rtl:pl-2';
        case LAYOUT.VERTICAL:
          return `mb-2`;
        case LAYOUT.INLINE:
          return `h-${CONTROL_SIZES[formItemLabelHeight]} ${label && 'ltr:pr-2 rtl:pl-2'}`;
        default:
          break;
      }
    };

    const formItemClass = classNames('form-item', formItemLayout, className, invalid ? 'invalid' : '');

    const formLabelClass = classNames('form-label', label && getFormLabelLayoutClass(), labelClass);

    const formLabelStyle = () => {
      if (formItemLayout === LAYOUT.HORIZONTAL) {
        return { ...style, ...{ minWidth: formItemLabelWidth } };
      }

      return { ...style };
    };

    const enterStyle = { opacity: 1, marginTop: 3, bottom: -21 };
    const exitStyle = { opacity: 0, marginTop: -10 };
    const initialStyle = exitStyle;

    return (
      <div ref={ref} className={formItemClass}>
        <div className="flex">
          <label htmlFor={htmlFor} className={formLabelClass} style={formLabelStyle()}>
            {asterisk && <span className="text-red-500 ltr:mr-1 rtl:ml-1">*</span>}
            {label}
            {label && formItemLayout !== 'vertical' && ':'}
          </label>
          {extra && <span>{extra}</span>}
        </div>
        <div className={formItemLayout === LAYOUT.HORIZONTAL ? 'relative flex w-full flex-col justify-center' : ''}>
          {children}
          <AnimatePresence mode="wait">
            {invalid && (
              <motion.div
                className="form-explain"
                initial={initialStyle}
                animate={enterStyle}
                exit={exitStyle}
                transition={{ duration: 0.15, type: 'tween' }}
              >
                {errorMessage}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    );
  },
);
