import { FieldInputProps, FormikProps } from 'formik';
import { CSSProperties, ForwardedRef, forwardRef, useState } from 'react';
import { useGetClientsQuery } from 'src/services';
import { Alert, Select, SelectOption, SelectProps } from '../ui';
import { Loading } from './Loading';

export type ClientSelectProps<T extends Record<string, unknown>> = SelectProps & {
  field?: FieldInputProps<T>;
  form?: FormikProps<T>;
  style?: CSSProperties;
};

export const ClientSelectInner = <T extends Record<string, unknown>>(
  { field, form, placeholder, size = 'sm', onChange: onChangeProp }: ClientSelectProps<T>,
  ref: ForwardedRef<HTMLElement>,
) => {
  const [nameSearch, setNameSearch] = useState('');
  const { data: clientsDataSearch, isLoading, isFetching, isError } = useGetClientsQuery({ Name: nameSearch });

  const defaultValue = field?.value ? { label: field.value.Name, value: field.value['@id'] } : null;

  const onChange = (option: SelectOption) => {
    setNameSearch(option.value);
    const clientInfo = clientsDataSearch?.find((client) => client['@id'] === option.value);
    //@ts-expect-error
    form?.setFieldValue(field?.name, clientInfo);
  };

  const Options = clientsDataSearch?.map((client) => {
    return { label: client.Name, value: client['@id'] };
  });

  if (isLoading || isFetching) {
    return <Loading loading={true} />;
  }

  return (
    <>
      <Select
        size={size}
        placeholder={placeholder}
        ref={ref}
        options={Options}
        defaultValue={defaultValue}
        onChange={onChangeProp || onChange}
      />
      {isError && <Alert>Unable to retrieve clients.</Alert>}
    </>
  );
};

export const ClientSelect = forwardRef(ClientSelectInner);
