import classNames from 'classnames';
import { ElementType, StyleHTMLAttributes, forwardRef } from 'react';

export type SkeletonProps = {
  animation?: boolean;
  asElement?: ElementType;
  className?: string;
  height?: number | string;
  style?: StyleHTMLAttributes<HTMLElement>;
  variant?: 'circle' | 'block';
  width?: number | string;
};

export const Skeleton = forwardRef<HTMLElement, SkeletonProps>(
  ({ animation = true, asElement: Component = 'span', className, height, style, variant = 'block', width }, ref) => {
    return (
      <Component
        ref={ref}
        className={classNames(
          'skeleton',
          variant === 'circle' && 'skeleton-circle',
          variant === 'block' && 'skeleton-block',
          animation && 'animate-pulse',
          className,
        )}
        style={{
          width,
          height,
          ...style,
        }}
      />
    );
  },
);
