import classNames from 'classnames';
import { ElementType, FunctionComponent, PropsWithChildren } from 'react';
import { Spinner } from '../ui';

type SharedLoadingProps = {
  loading: boolean;
  spinnerClass?: string;
  className?: string;
  asElement: ElementType;
  customLoader?: ElementType;
};

const DefaultLoading: FunctionComponent<PropsWithChildren<SharedLoadingProps>> = ({
  loading,
  children,
  spinnerClass,
  className,
  asElement: Component,
  customLoader,
}) => {
  return loading ? (
    <Component className={classNames(!customLoader && 'flex h-full items-center justify-center', className)}>
      {customLoader ? <>{customLoader}</> : <Spinner className={spinnerClass} size={40} />}
    </Component>
  ) : (
    <>{children}</>
  );
};

const CoveredLoading: FunctionComponent<PropsWithChildren<SharedLoadingProps>> = ({
  loading,
  children,
  spinnerClass,
  className,
  asElement: Component,
  customLoader,
}) => {
  return (
    <Component className={classNames(loading ? 'relative' : '', className)}>
      {children}
      {loading && (
        <div className="absolute inset-0 h-full w-full bg-white bg-opacity-50 dark:bg-gray-800 dark:bg-opacity-60" />
      )}
      {loading && (
        <div className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform">
          {customLoader ? <>{customLoader}</> : <Spinner className={spinnerClass} size={40} />}
        </div>
      )}
    </Component>
  );
};

export type LoadingProps = {
  loading: boolean;
  spinnerClass?: string;
  className?: string;
  asElement?: ElementType;
  customLoader?: ElementType;
  type?: 'default' | 'cover';
};

export const Loading: FunctionComponent<PropsWithChildren<LoadingProps>> = ({
  type = 'default',
  loading = false,
  asElement = 'div',
  ...otherProps
}) => {
  switch (type) {
    case 'default':
      return <DefaultLoading loading={loading} asElement={asElement} {...otherProps} />;
    case 'cover':
      return <CoveredLoading loading={loading} asElement={asElement} {...otherProps} />;
    default:
      return <DefaultLoading loading={loading} asElement={asElement} {...otherProps} />;
  }
};
