import { useRef, useEffect, useMemo } from 'react';

export const useCallbackRef = <T>(cb: (value: T) => void) => {
  const cbRef = useRef(cb);

  useEffect(() => {
    cbRef.current = cb;
  });

  return useMemo(
    () =>
      (...args: unknown[]) =>
        //@ts-ignore
        cbRef.current?.(...args),
    [],
  );
};
