import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren, ReactNode, useContext } from 'react';
import { useUniqueId } from '../hooks';
import { GroupContextProvider } from './context/groupContext';
import MenuContext from './context/menuContext';

export type MenuGroupProps = {
  label?: string | ReactNode;
  className?: string;
};

export const MenuGroup: FunctionComponent<PropsWithChildren<MenuGroupProps>> = ({
  label = null,
  children,
  className,
}) => {
  const { variant, sideCollapsed } = useContext(MenuContext);

  const menuGroupDefaultClass = 'menu-group';
  const menuGroupClass = classNames(menuGroupDefaultClass, className);

  const entityHeaderId = useUniqueId('entity-header-');

  return (
    <div className={menuGroupClass}>
      {label && !sideCollapsed && (
        <div className={classNames('menu-title', `menu-title-${variant}`)} id={entityHeaderId}>
          {label}
        </div>
      )}
      <GroupContextProvider value={null}>
        <ul>{children}</ul>
      </GroupContextProvider>
    </div>
  );
};
