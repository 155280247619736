import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import clientStateReducer from 'src/views/clients/store/stateSlice';
import workOrderStateReducer from 'src/views/planninggroup/store/stateSlice';
import workOrderCallsStateReducer from 'src/views/calls/store/stateSlice';
import stockContentStateReducer from 'src/views/stocks/store/stateSlice';
import productReducer from 'src/views/products/productsmanagement/store/stateSlice';
import itinerantStateReducer from 'src/views/admin/store/stateSlice';
import { mercureApi, mobystockApi, mobystockAuth } from 'src/services';
import { themeSliceReducer } from './themeSlice';
import { authSliceReducer } from './authSlice';
import { commonSliceReducer } from './commonSlice';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(mobystockAuth.middleware, mobystockApi.middleware, mercureApi.middleware),
  reducer: {
    [mobystockAuth.reducerPath]: mobystockAuth.reducer,
    [mobystockApi.reducerPath]: mobystockApi.reducer,
    [mercureApi.reducerPath]: mercureApi.reducer,
    auth: authSliceReducer,
    clientState: clientStateReducer,
    itinerantState: itinerantStateReducer,
    workOrderState: workOrderStateReducer,
    workOrderCallsState: workOrderCallsStateReducer,
    stockContentState: stockContentStateReducer,
    productState: productReducer,
    theme: themeSliceReducer,
    common: commonSliceReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
