import { PropsWithChildren, ReactNode, forwardRef, MouseEvent } from 'react';
import classNames from 'classnames';
import { useConfig } from '../ConfigProvider';

export type CardProps = {
  className?: string;
  clickable?: boolean;
  onClick?: (e: MouseEvent) => void;
  bordered?: boolean;
  bodyClass?: string;
  header?: string | ReactNode;
  headerClass?: string;
  headerBorder?: boolean;
  headerExtra?: string;
  footer?: string | ReactNode;
  footerClass?: string;
  footerBorder?: boolean;
};

export const Card = forwardRef<HTMLDivElement, PropsWithChildren<CardProps>>(
  (
    {
      bordered: borderedProp = false,
      children,
      className,
      clickable = false,
      onClick,
      bodyClass,
      header,
      headerClass,
      headerBorder = true,
      headerExtra,
      footer,
      footerClass,
      footerBorder = true,
      ...rest
    },
    ref,
  ) => {
    const { cardBordered } = useConfig();

    const bordered = cardBordered || borderedProp;

    const cardClass = classNames(
      'card',
      className,
      bordered ? `card-border` : `card-shadow`,
      clickable && 'cursor-pointer user-select-none',
    );

    const cardBodyClasss = classNames('card-body', bodyClass);
    const cardHeaderClass = classNames(
      'card-header',
      headerBorder && 'card-header-border',
      headerExtra && 'card-header-extra',
      headerClass,
    );
    const cardFooterClass = classNames('card-footer', footerBorder && `card-footer-border`, footerClass);

    const renderHeader = () => {
      if (typeof header === 'string') {
        return <h4>{header}</h4>;
      }
      return <>{header}</>;
    };

    const onCardClick = (e: MouseEvent) => {
      onClick?.(e);
    };

    return (
      <div className={cardClass} ref={ref} {...rest} onClick={onCardClick}>
        {header && (
          <div className={cardHeaderClass}>
            {renderHeader()}
            {headerExtra && <span>{headerExtra}</span>}
          </div>
        )}
        <div className={cardBodyClasss}>{children}</div>
        {footer && <div className={cardFooterClass}>{footer}</div>}
      </div>
    );
  },
);
