import { Header } from '@tanstack/react-table';
import classNames from 'classnames';
import { ElementType, PropsWithChildren, forwardRef } from 'react';

export type ThProps = { className?: string; asElement?: ElementType } & Partial<Header<unknown, any>>;

export const Th = forwardRef<HTMLElement, PropsWithChildren<ThProps>>(
  ({ children, className, asElement: Component = 'th', ...otherProps }, ref) => {
    const thClass = classNames(Component !== 'th' && 'th', className);

    return (
      <Component className={thClass} {...otherProps} ref={ref}>
        {children}
      </Component>
    );
  },
);
