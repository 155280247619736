import { AnimatePresence, TargetAndTransition, motion } from 'framer-motion';
import { PropsWithChildren, RefObject, forwardRef, useContext } from 'react';
import { ValueOf } from 'src/types';
import { useUncertainRef, useUniqueId } from '../hooks';
import { Placement } from '../utils';
import DropdownMenuContext, {
  DropdownMenuContextProvider,
  useDropdownMenuContext,
} from './context/dropdownMenuContext';
import { MenuContextProvider } from './context/menuContext';

export type MenuProps = {
  classPrefix?: string;
  activeKey: string;
  onSelect: () => void;
  onKeyDown?: () => void;
  hidden?: boolean;
  placement?: ValueOf<Placement>;
  menuClass?: string;
  className?: string;
  onToggle?: (arg: any, e: MouseEvent) => void;
};

export const Menu = forwardRef<HTMLUListElement, PropsWithChildren<MenuProps>>(
  ({ children, classPrefix, activeKey, onSelect, onKeyDown, hidden, placement, menuClass, ...otherProps }, ref) => {
    const menuRef = useUncertainRef(ref) as RefObject<HTMLUListElement>;
    const menuId = useUniqueId('menu-');
    const upperMenuControl = useContext(DropdownMenuContext);
    const menuControl = useDropdownMenuContext(menuRef, upperMenuControl);

    const getTransform = (deg: number) => {
      const rotate = `rotateX(${deg}deg)`;
      if (placement && placement.includes('center')) {
        return `${rotate} translateX(-50%)`;
      }
      return rotate;
    };

    const enterStyle: TargetAndTransition = {
      opacity: 1,
      visibility: 'visible',
      transform: getTransform(0),
    };
    const exitStyle: TargetAndTransition = {
      opacity: 0,
      visibility: 'hidden',
      transform: getTransform(40),
    };
    const initialStyle = exitStyle;

    return (
      <MenuContextProvider
        value={{
          activeKey,
          onSelect,
        }}
      >
        <DropdownMenuContextProvider value={menuControl}>
          <AnimatePresence mode="wait">
            {!hidden && (
              <motion.ul
                id={menuId}
                ref={menuRef}
                // @ts-expect-error
                initial={initialStyle}
                animate={enterStyle}
                exit={exitStyle}
                transition={{ duration: 0.15, type: 'tween' }}
                className={menuClass}
                {...otherProps}
              >
                {children}
              </motion.ul>
            )}
          </AnimatePresence>
        </DropdownMenuContextProvider>
      </MenuContextProvider>
    );
  },
);
