import classNames from 'classnames';
import React, { PropsWithChildren, forwardRef } from 'react';

export type TagProps = {
  className?: string;
  prefix?: boolean | React.ReactNode;
  suffix?: boolean | React.ReactNode;
  prefixClass?: string;
  suffixClass?: string;
  onClick?: () => void;
};

export const Tag = forwardRef<HTMLDivElement, PropsWithChildren<TagProps>>(
  ({ className, children, prefix = false, suffix = false, prefixClass, suffixClass, ...otherProps }, ref) => {
    return (
      <div className={classNames('tag', className)} ref={ref} {...otherProps}>
        {prefix && typeof prefix === 'boolean' && <span className={classNames('tag-affix tag-prefix', prefixClass)} />}
        {typeof prefix === 'object' && prefix}
        {children}
        {suffix && typeof suffix === 'boolean' && <span className={classNames('tag-affix tag-suffix', suffixClass)} />}
        {typeof suffix === 'object' && suffix}
      </div>
    );
  },
);
