import classNames from 'classnames';
import { PropsWithChildren, ReactNode, forwardRef } from 'react';
import { HiCheckCircle } from 'react-icons/hi';
import { useThemeClass } from 'src/hooks';

export type SegmentItemOptionProps = {
  active: boolean;
  className?: string;
  customCheck: string | ReactNode;
  defaultGutter?: boolean;
  disabled: boolean;
  hoverable: boolean;
  onSegmentItemClick: () => void;
};

export const SegmentItemOption = forwardRef<HTMLDivElement, PropsWithChildren<SegmentItemOptionProps>>(
  ({ active, children, className, customCheck, defaultGutter, disabled, hoverable, onSegmentItemClick }, ref) => {
    const { textTheme, borderTheme, ringTheme } = useThemeClass();

    return (
      <div
        ref={ref}
        className={classNames(
          'flex',
          !customCheck && 'justify-between',
          'items-center',
          'border',
          'rounded-md ',
          'border-gray-200 dark:border-gray-600',
          defaultGutter && 'px-4 py-5',
          'cursor-pointer',
          'select-none',
          'w-100',
          active && `ring-1 ${ringTheme} ${borderTheme}`,
          hoverable && `hover:ring-1 hover:${ringTheme} hover:${borderTheme}`,
          disabled && 'cursor-not-allowed opacity-50',
          className,
        )}
        onClick={onSegmentItemClick}
      >
        {children}
        {active && !customCheck && <HiCheckCircle className={classNames(textTheme, 'text-2xl')} />}
        {active && customCheck}
      </div>
    );
  },
);
