import { Sorter } from './Sorter';
import { Expander } from './Expander';
import { TBody } from './TBody';
import { TFoot } from './TFoot';
import { THead } from './THead';
import { Table as BaseTable } from './Table';
import { Td } from './Td';
import { Th } from './Th';
import { Tr } from './Tr';

export const Table = Object.assign(BaseTable, { THead, TBody, TFoot, Th, Tr, Td, Sorter, Expander });

export type PagingData = {
  total: number;
  pageIndex: number;
  pageSize: number;
  sort: { key: string; order: 'asc' | 'desc' };
  query?: string;
};
