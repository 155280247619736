import { Input, Button, Checkbox, FormItem, FormContainer, Alert } from 'src/components/ui';
import { PasswordInput, ActionLink } from 'src/components';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FunctionComponent } from 'react';
import { useLoginMutation } from 'src/services';
import { useTranslation } from 'react-i18next';
import { isFetchBaseQueryError } from 'src/utils';

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Please enter your email'),
  password: Yup.string().required('Please enter your password'),
  rememberMe: Yup.bool(),
});

export type LogInFormProps = {
  disableSubmit?: boolean;
  className?: string;
  forgotPasswordUrl?: string;
  signUpUrl?: string;
};

export const LogInForm: FunctionComponent<LogInFormProps> = ({
  //   disableSubmit = false,
  //   className,
  forgotPasswordUrl = '/forgot-password',
  // signUpUrl = '/sign-up',
}) => {
  const [login, { isLoading: isLoggingIn, error }] = useLoginMutation();
  const { t } = useTranslation();

  const onLogIn = async (values: { email: string; password: string }) => {
    try {
      await login(values).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          email: 'tech1@maurice.com',
          password: 'pwd',
          // rememberMe: true,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onLogIn(values);
        }}
      >
        {({ touched, errors }) => (
          <Form>
            <FormContainer>
              <FormItem
                label={t('auth.login.email')}
                invalid={errors.email && touched.email}
                errorMessage={errors.email}
              >
                <Field
                  type="text"
                  autoComplete="off"
                  name="email"
                  placeholder={t('auth.login.email')}
                  component={Input}
                />
              </FormItem>
              <FormItem
                label={t('auth.login.password')}
                invalid={errors.password && touched.password}
                errorMessage={errors.password}
              >
                <Field
                  autoComplete="off"
                  name="password"
                  placeholder={t('auth.login.password')}
                  component={PasswordInput}
                />
              </FormItem>
              <div className="mb-6 flex justify-between">
                {/* eslint-disable-next-line react/no-children-prop */}
                <Field className="mb-0" name="rememberMe" component={Checkbox} children={t('auth.login.rememberme')} />
                <ActionLink to={forgotPasswordUrl}>{t('auth.login.forgotpassword')}</ActionLink>
              </div>
              {isFetchBaseQueryError(error) && <Alert type="danger">{error.data as string}</Alert>}
              <Button block loading={isLoggingIn} variant="solid" type="submit">
                {isLoggingIn ? t('auth.login.buttonprogress') : t('auth.login.button')}
              </Button>
              {/* <div className="mt-4 text-center">
                <span>Don't have an account yet? </span>
                <ActionLink to={signUpUrl}>Sign up</ActionLink>
              </div> */}
            </FormContainer>
          </Form>
        )}
      </Formik>
    </div>
  );
};
