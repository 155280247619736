import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { useThemeClass } from 'src/hooks';

export type ActionLinkProps = {
  themeColor?: boolean;
  to: string;
  className?: string;
  href?: string;
};
export const ActionLink: FunctionComponent<PropsWithChildren<ActionLinkProps>> = ({
  children,
  className,
  themeColor = true,
  to,
  href = '',
  ...otherProps
}) => {
  const { textTheme } = useThemeClass();

  const classNameProps = {
    className: classNames(themeColor && textTheme, 'hover:underline', className),
  };

  return to ? (
    <Link to={to} {...classNameProps} {...otherProps}>
      {children}
    </Link>
  ) : (
    <a href={href} {...classNameProps} {...otherProps}>
      {children}
    </a>
  );
};
