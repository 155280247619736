import classNames from 'classnames';
import { PropsWithChildren, StyleHTMLAttributes, forwardRef } from 'react';

export type BadgeProps = {
  content?: string | number;
  maxCount?: number;
  className?: string;
  innerClass?: string;
  badgeStyle?: StyleHTMLAttributes<HTMLSpanElement>;
};

export const Badge = forwardRef<HTMLSpanElement, PropsWithChildren<BadgeProps>>(
  ({ content, maxCount = 99, className, innerClass, children, badgeStyle, ...otherProps }, ref) => {
    const dot = typeof content !== 'number' && typeof content !== 'string';

    const badgeClass = classNames(dot ? 'badge-dot' : 'badge', innerClass);

    const renderBadge = () => {
      if (children) {
        return (
          <span className={classNames('badge-wrapper', className)} ref={ref} {...otherProps}>
            <span className={classNames(badgeClass, 'badge-inner')} style={badgeStyle}>
              {typeof content === 'number' && content > maxCount ? `${maxCount}+` : content}
            </span>
            {children}
          </span>
        );
      }
      return (
        <span className={classNames(badgeClass, className)} ref={ref} style={badgeStyle} {...otherProps}>
          {content}
        </span>
      );
    };

    return renderBadge();
  },
);
