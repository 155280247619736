import classNames from 'classnames';
import { FieldInputProps } from 'formik';
import { isNil } from 'lodash';
import { ChangeEvent, ForwardedRef, PropsWithChildren, forwardRef, useCallback, useContext, useState } from 'react';
import { useConfig } from '../ConfigProvider';
import CheckboxGroupContext from './context';

export type CheckboxProps = {
  color?: string;
  className?: string;
  onChange?: (bool: boolean, ev: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  readOnly?: boolean;
  name?: string;
  defaultChecked?: boolean;
  value?: unknown;
  checked: boolean;
  labelRef?: ForwardedRef<HTMLLabelElement>;
  field?: FieldInputProps<HTMLInputElement>;
};

export const Checkbox = forwardRef<HTMLInputElement, PropsWithChildren<CheckboxProps>>(
  (
    {
      color,
      className,
      onChange,
      children,
      disabled,
      readOnly,
      defaultChecked,
      value,
      checked: controlledChecked,
      labelRef,
      field,
      ...otherProps
    },
    ref,
  ) => {
    const {
      name: nameContext,
      value: groupValue,
      onChange: onGroupChange,
      color: colorContext,
    } = useContext(CheckboxGroupContext);

    const { name = nameContext } = otherProps;

    const { themeColor, primaryColorLevel } = useConfig();

    const isChecked = useCallback(() => {
      if (!isNil(groupValue) && !isNil(value)) {
        return groupValue.some((i) => i === value);
      }
      return controlledChecked || defaultChecked;
    }, [controlledChecked, groupValue, value, defaultChecked]);

    const [checkboxChecked, setCheckboxChecked] = useState(isChecked());

    const getControlProps = () => {
      let checkedValue = checkboxChecked;

      let groupChecked = { checked: checkedValue };
      let singleChecked: { value?: boolean; checked?: boolean; defaultChecked?: boolean } = { value: checkedValue };

      if (controlledChecked) {
        singleChecked.checked = controlledChecked;
      }

      if (field) {
        checkedValue = typeof field.value === 'boolean' ? field.value : defaultChecked;
        singleChecked = { value: checkedValue, checked: checkedValue };
      }

      if (typeof groupValue !== 'undefined') {
        groupChecked = { checked: groupValue?.includes(value) };
      }

      if (defaultChecked) {
        singleChecked.defaultChecked = defaultChecked;
      }
      return typeof groupValue !== 'undefined' ? groupChecked : singleChecked;
    };

    const controlProps = getControlProps();

    const onCheckboxChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        let nextChecked = !checkboxChecked;

        if (typeof groupValue !== 'undefined') {
          nextChecked = !groupValue?.includes(value);
        }

        if (disabled || readOnly) {
          return;
        }

        setCheckboxChecked(nextChecked);
        onChange?.(nextChecked, e);
        onGroupChange?.(value, nextChecked, e);
      },
      [checkboxChecked, disabled, readOnly, setCheckboxChecked, onChange, value, onGroupChange, groupValue],
    );

    const checkboxColor = color || colorContext || `${themeColor}-${primaryColorLevel}`;

    const checkboxDefaultClass = `checkbox text-${checkboxColor}`;
    const checkboxColorClass = disabled && 'disabled';
    const labelDefaultClass = `checkbox-label`;
    const labelDisabledClass = disabled && 'disabled';

    const checkBoxClass = classNames(checkboxDefaultClass, checkboxColorClass);

    const labelClass = classNames(labelDefaultClass, labelDisabledClass, className);

    return (
      <label ref={labelRef} className={labelClass}>
        {/* @ts-expect-error */}
        <input
          ref={ref}
          className={checkBoxClass}
          type="checkbox"
          disabled={disabled}
          readOnly={readOnly}
          onChange={onCheckboxChange}
          name={name!}
          {...controlProps}
          {...field}
          {...otherProps}
        />
        {children ? (
          <span className={classNames('ltr:ml-2 rtl:mr-2', disabled ? 'opacity-50' : '')}>{children}</span>
        ) : null}
      </label>
    );
  },
);
