import classNames from 'classnames';
import { MouseEvent, ReactNode, StyleHTMLAttributes, forwardRef, useCallback, useState } from 'react';
import { CloseButton } from '../Buttons';
import { StatusIcon } from '../StatusIcon';
import { useTimeout } from '../hooks';

export type NotificationProps = {
  duration?: number;
  onClose?: (e: MouseEvent) => void;
  type: 'info' | 'success' | 'warning' | 'danger';
  title: ReactNode;
  closable?: boolean;
  className?: string;
  width?: number;
  customIcon?: ReactNode;
  triggerByToast?: boolean;
  style?: StyleHTMLAttributes<HTMLDivElement>;
  children: ReactNode | (() => ReactNode);
};

export const Notification = forwardRef<HTMLDivElement, NotificationProps>(
  (
    {
      duration = 3000,
      onClose,
      type,
      title,
      closable = false,
      className,
      children,
      width = 350,
      customIcon,
      triggerByToast = false,
      style,
      ...otherProps
    },
    ref,
  ) => {
    const [display, setDisplay] = useState('show');

    const { clear } = useTimeout(onClose, duration, duration > 0);

    const handleClose = useCallback(
      (e: MouseEvent) => {
        setDisplay('hiding');
        onClose?.(e);
        clear();
        if (!triggerByToast) {
          setTimeout(() => {
            setDisplay('hide');
          }, 400);
        }
      },
      [onClose, clear, triggerByToast],
    );

    const notificationClass = classNames('notification', className);

    if (display === 'hide') {
      return null;
    }

    return (
      <div ref={ref} {...otherProps} className={notificationClass} style={{ width: width, ...style }}>
        <div className={classNames('notification-content', !children && 'no-child')}>
          {type && !customIcon ? (
            <div className="mr-3">
              <StatusIcon type={type} />
            </div>
          ) : null}
          {customIcon && <div className="mr-3">{customIcon}</div>}
          <div className="mr-4">
            {title && <div className={classNames('notification-title', children && 'mb-1')}>{title}</div>}
            <div className="notification-description">{typeof children === 'function' ? children() : children}</div>
          </div>
        </div>
        {closable && (
          <CloseButton className="notification-close" defaultStyle={false} absolute={true} onClick={handleClose} />
        )}
      </div>
    );
  },
);
