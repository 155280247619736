import classNames from 'classnames';
import { ReactElement, ReactNode, forwardRef, useCallback, useContext } from 'react';
import { CONTROL_SIZES, SIZES } from '../utils/constant';
import SegmentContext, { useSegment } from './context';

const unwrapArray = (arg: unknown) => (Array.isArray(arg) ? arg[0] : arg);

export type SegmentItemProps = {
  value: string;
  className?: string;
  disabled?: boolean;
  children: (() => ReactElement)[] | ReactNode;
};

export const SegmentItem = forwardRef<unknown, SegmentItemProps>(
  ({ value: valueProp, children, className, disabled = false, ...otherProps }, ref) => {
    const { size } = useContext(SegmentContext);

    const { value: valueContext, onActive, onDeactivate, selectionType } = useSegment();

    const active = valueContext?.includes(valueProp);

    const getSegmentSize = useCallback(() => {
      let sizeClass = '';
      switch (size) {
        case SIZES.LG:
          sizeClass = classNames(`h-${CONTROL_SIZES.lg} md:px-8 py-2 px-4 text-base`);
          break;
        case SIZES.SM:
          sizeClass = classNames(`h-${CONTROL_SIZES.sm} px-3 py-2 text-sm`);
          break;
        case SIZES.XS:
          sizeClass = classNames(`h-${CONTROL_SIZES.xs} px-3 py-1 text-xs`);
          break;
        default:
          sizeClass = classNames(`h-${CONTROL_SIZES.md} md:px-8 py-2 px-4`);
          break;
      }
      return sizeClass;
    }, [size]);

    const onSegmentItemClick = () => {
      if (!disabled) {
        if (!active) {
          if (selectionType === 'single') {
            onActive && onActive([valueProp]);
          }
          if (selectionType === 'multiple') {
            const nextValue = [...(valueContext as string[]), ...[valueProp]];
            onActive && onActive(nextValue);
          }
        } else if (selectionType === 'multiple') {
          onDeactivate && onDeactivate(valueProp);
        }
      }
    };

    const childrenProps = {
      ref: ref,
      active,
      onSegmentItemClick,
      disabled,
      value: valueProp,
      ...otherProps,
    };

    return typeof children === 'function' ? (
      unwrapArray(children)(childrenProps)
    ) : (
      <button
        className={classNames(
          'segment-item segment-item-default',
          active && 'segment-item-active',
          disabled && 'segment-item-disabled',
          getSegmentSize(),
          className,
        )}
        onClick={onSegmentItemClick}
        {...otherProps}
      >
        {children as ReactNode}
      </button>
    );
  },
);
