import { FunctionComponent } from 'react';
import { AvatarProps, Skeleton, SkeletonProps, Table } from 'src/components/ui';

const { Tr, Td, TBody } = Table;

export type TableRowSkeletonProps = {
  columns: number;
  rows: number;
  avatarInColumns: number[];
  avatarProps?: AvatarProps & SkeletonProps;
};

export const TableRowSkeleton: FunctionComponent<TableRowSkeletonProps> = ({
  columns = 1,
  rows = 10,
  avatarInColumns = [],
  avatarProps,
}) => {
  return (
    <TBody>
      {Array.from(new Array(rows), (_, i) => i + 0).map((row) => (
        <Tr key={`row-${row}`}>
          {Array.from(new Array(columns), (_, i) => i + 0).map((col) => (
            <Td key={`col-${col}`}>
              <div className="flex flex-auto items-center gap-2">
                {avatarInColumns.includes(col) && (
                  <div>
                    <Skeleton variant="circle" {...avatarProps} />
                  </div>
                )}
                <Skeleton />
              </div>
            </Td>
          ))}
        </Tr>
      ))}
    </TBody>
  );
};
