import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { IS_PROD, RELEASE_NAME } from './config/env';
import './assets/styles/app.css';
import './assets/locales';

if (IS_PROD) {
  console.log(`[app] initialized sentry`);
  Sentry.init({
    dsn: '',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    release: RELEASE_NAME,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(<App />);
