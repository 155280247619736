import classNames from 'classnames';
import { ForwardedRef, MouseEvent, ReactNode, forwardRef, useEffect, useState } from 'react';
import { useConfig } from '../ConfigProvider';
import { Spinner } from '../Spinner';

export type SwitcherProps = {
  checked?: boolean;
  checkedContent?: ReactNode;
  className?: string;
  color?: string;
  defaultChecked: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  labelRef?: ForwardedRef<HTMLLabelElement>;
  name?: string;
  onChange?: (val: boolean, event: MouseEvent) => void;
  readOnly?: boolean;
  unCheckedContent?: ReactNode;
  field?: any;
};

export const Switcher = forwardRef<HTMLInputElement, SwitcherProps>((props, ref) => {
  const {
    checked,
    checkedContent,
    className,
    color,
    defaultChecked,
    disabled,
    isLoading = false,
    labelRef,
    name,
    onChange,
    readOnly,
    unCheckedContent,
    field,
    ...rest
  } = props;

  const { themeColor, primaryColorLevel } = useConfig();

  const [switcherChecked, setSwitcherChecked] = useState(defaultChecked || checked);

  useEffect(() => {
    if (typeof checked !== 'undefined') {
      setSwitcherChecked(checked);
    }
  }, [checked]);

  const getControlProps = () => {
    let checkedValue = switcherChecked;

    let checked: { value: boolean; defaultChecked?: boolean; checked?: boolean } = { value: checkedValue! };

    if (field) {
      checkedValue = typeof field.value === 'boolean' ? field.value : defaultChecked;
      checked = { value: checkedValue, checked: checkedValue } as { value: boolean; checked: boolean };
    }

    if (defaultChecked) {
      checked.defaultChecked = defaultChecked;
    }
    return checked;
  };

  const controlProps = getControlProps();

  const handleChange = (e: MouseEvent) => {
    const nextChecked = !switcherChecked;

    if (disabled || readOnly || isLoading) {
      return;
    }

    if (typeof checked === 'undefined') {
      setSwitcherChecked(nextChecked);
      onChange?.(nextChecked, e);
    } else {
      onChange?.(switcherChecked!, e);
    }
  };

  const switcherColor = color || `${themeColor}-${primaryColorLevel}`;

  const switcherClass = classNames(
    'switcher',
    (switcherChecked || controlProps.checked) && `switcher-checked bg-${switcherColor} dark:bg-${switcherColor}`,
    disabled && 'switcher-disabled',
    className,
  );

  return (
    <label ref={labelRef} className={switcherClass}>
      <input
        ref={ref}
        type="checkbox"
        disabled={disabled}
        readOnly={readOnly}
        onChange={handleChange}
        name={name}
        {...controlProps}
        {...field}
        {...rest}
      />
      {isLoading ? (
        <Spinner
          className={classNames(
            'switcher-toggle-loading',
            switcherChecked ? 'switcher-checked-loading' : 'switcher-uncheck-loading',
          )}
        />
      ) : (
        <div className="switcher-toggle" />
      )}
      <span className="switcher-content">{switcherChecked ? checkedContent : unCheckedContent}</span>
    </label>
  );
});
