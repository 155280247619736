import classNames from 'classnames';
import { ElementType, PropsWithChildren, forwardRef } from 'react';

export type THeadProps = { className?: string; asElement?: ElementType };

export const THead = forwardRef<HTMLElement, PropsWithChildren<THeadProps>>(
  ({ children, asElement: Component = 'thead', className, ...otherProps }, ref) => {
    const tHeadClass = classNames(Component !== 'thead' && 'thead', className);

    return (
      <Component className={tHeadClass} {...otherProps} ref={ref}>
        {children}
      </Component>
    );
  },
);
