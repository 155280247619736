import { PropsWithChildren, forwardRef } from 'react';
import classNames from 'classnames';
import { MenuContextProvider } from './context/menuContext';
import { useConfig } from '../ConfigProvider';
import { ThemeState } from 'src/store';

export type MenuProps = {
  className?: string;
  defaultActiveKeys?: string[];
  defaultExpandedKeys?: string[];
  menuItemHeight?: number;
  onSelect?: () => void;
  sideCollapsed?: boolean;
  variant?: ThemeState['navMode'];
};

export const Menu = forwardRef<HTMLDivElement, PropsWithChildren<MenuProps>>(
  (
    {
      children,
      className,
      defaultActiveKeys = [],
      defaultExpandedKeys = [],
      menuItemHeight = 40,
      onSelect,
      sideCollapsed = false,
      variant = 'light',
      ...otherProps
    },
    ref,
  ) => {
    const menuDefaultClass = 'menu';

    const { themeColor, primaryColorLevel } = useConfig();

    const menuColor = () => {
      if (variant === 'themed') {
        return `bg-${themeColor}-${primaryColorLevel} ${menuDefaultClass}-${variant}`;
      }
      return `${menuDefaultClass}-${variant}`;
    };

    const menuClass = classNames(menuDefaultClass, menuColor(), className);

    return (
      <nav ref={ref} className={menuClass} {...otherProps}>
        <MenuContextProvider
          value={{
            onSelect,
            menuItemHeight,
            variant,
            sideCollapsed,
            defaultExpandedKeys,
            defaultActiveKeys,
          }}
        >
          {children}
        </MenuContextProvider>
      </nav>
    );
  },
);
