// Vite environment
export const NODE_ENV = import.meta.env.MODE;
export const IS_REMOTE_DEV = NODE_ENV === 'remotedev';
export const IS_DEV = import.meta.env.DEV && !IS_REMOTE_DEV;
export const IS_PROD = import.meta.env.PROD && NODE_ENV === 'production';
export const IS_STAGING = NODE_ENV === 'staging';
export const IS_TEST = NODE_ENV === 'test';

// App environment
export const USE_FIXTURES = IS_DEV && false;
export const USE_MOCK = IS_DEV && false;

// Application name
export const APP_NAME = 'Mobystock';
export const PACKAGE_NAME = '';
export const PACKAGE_VERSION = '';
export const GIT_COMMIT_HASH = '';
export const GIT_COMMIT_DATE: Date = new Date(parseInt('0', 10) * 1000);
export const RELEASE_NAME = `${PACKAGE_NAME}@${PACKAGE_VERSION}+${GIT_COMMIT_HASH}`;
// console.log(`[app] ${RELEASE_NAME} (${GIT_COMMIT_DATE.toISOString()}) started in ${NODE_ENV} mode`);

export const FAKE_API_BASE_URL = '/api';
export const FAKE_ENDPOINTS = {
  api: FAKE_API_BASE_URL,
};

export const MOBYSTOCK_API_ENDPOINTS = {
  production: 'https://api.mobystock.tech',
  staging: 'https://staging.api.mobystock.tech',
  dev2: 'https://api-dev2.mobystock.tech',
  preprod: 'https://api-preprod.mobystock.tech',
  predev: 'https://api-predev.mobystock.tech',
  remotedev: 'https://dev.api.mobystock.tech',
  dev: 'https://api-predev.mobystock.tech',
};
// export const MOBYSTOCK_API_ENDPOINTS = {
//   production: 'https://api.mobystock.tech',
//   dev2: 'https://api-dev2.mobystock.tech',
//   preprod: 'https://api-preprod.mobystock.tech',
//   predev: 'https://api-predev.mobystock.tech',
//   dev: 'https://api-dev.mobystock.tech',
//   // "local": 'http://192.168.1.40'
// };

const DEV_TARGET = 'predev';

export const MOBYSTOCK_API_BASE_URL = IS_PROD
  ? MOBYSTOCK_API_ENDPOINTS['production']
  : IS_STAGING
  ? MOBYSTOCK_API_ENDPOINTS['staging']
  : IS_REMOTE_DEV
  ? MOBYSTOCK_API_ENDPOINTS['remotedev']
  : MOBYSTOCK_API_ENDPOINTS['dev'];

//Mercure
export const MERCURE_ENDPOINTS = {
  production: `${MOBYSTOCK_API_ENDPOINTS['production']}/.well-known/mercure`,
  staging: `${MOBYSTOCK_API_ENDPOINTS['staging']}/.well-known/mercure`,
  dev2: `${MOBYSTOCK_API_ENDPOINTS['dev2']}/.well-known/mercure`,
  preprod: `${MOBYSTOCK_API_ENDPOINTS['preprod']}/.well-known/mercure`,
  predev: `${MOBYSTOCK_API_ENDPOINTS['predev']}/.well-known/mercure`,
  remotedev: `${MOBYSTOCK_API_ENDPOINTS['remotedev']}/.well-known/mercure`,
  dev: `${MOBYSTOCK_API_ENDPOINTS['dev']}/.well-known/mercure`,
};

export const MERCURE_BASE_URL = IS_PROD
  ? MERCURE_ENDPOINTS['production']
  : IS_STAGING
  ? MERCURE_ENDPOINTS['staging']
  : IS_REMOTE_DEV
  ? MERCURE_ENDPOINTS['remotedev']
  : MERCURE_ENDPOINTS['dev'];
