import { createSlice } from '@reduxjs/toolkit';
import { Product } from 'src/types';

type ProductStateType = {
  drawerOpen: boolean;
  selectedProduct: Product | null;
};

const initialState: ProductStateType = {
  drawerOpen: false,
  selectedProduct: null,
};

const productStateSlice = createSlice({
  name: 'products/state',
  initialState,
  reducers: {
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
    setDrawerOpen: (state) => {
      state.drawerOpen = true;
    },
    setDrawerClose: (state) => {
      state.drawerOpen = false;
    },
  },
});

export const { setSelectedProduct, setDrawerOpen, setDrawerClose } = productStateSlice.actions;

export default productStateSlice.reducer;
