import { FieldInputProps, FormikProps } from 'formik';
import isNil from 'lodash/isNil';
import React, { CSSProperties, ForwardedRef, ReactNode, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { HiOutlinePlusCircle, HiX } from 'react-icons/hi';
import { useConfig } from '../ui';
import { Tag } from '../ui/Tag';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

export type MultiTextInputWithDisplayProps<T> = {
  field: FieldInputProps<T[keyof T]>;
  form: FormikProps<T>;
  placeholder?: string;
  invalid?: boolean;
  prefix?: string | ReactNode;
  suffix?: string | ReactNode;
  type?: string;
  className?: string;
  style?: CSSProperties;
  disabled?: boolean;
};

const MultiTextInputWithDisplayInner = <T extends Record<string, unknown>>(
  {
    field,
    form,
    placeholder,
    type = 'text',
    prefix,
    suffix,
    className,
    style,
    disabled,
    invalid,
  }: MultiTextInputWithDisplayProps<T>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const defaultValue = field?.value && Array.isArray(field.value) ? field.value : [];
  const [tagList, setTagList] = useState<string[]>(defaultValue);
  const [currentInput, setCurrentInput] = useState<string>('');

  const [prefixGutter, setPrefixGutter] = useState(0);
  const [suffixGutter, setSuffixGutter] = useState(0);
  const { themeColor, primaryColorLevel, direction } = useConfig();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentInput(event.target.value);
  };

  const handleAddInput = () => {
    if (currentInput.trim() !== '') {
      const newTagList = [...tagList, currentInput.trim()];
      setTagList(newTagList);
      setCurrentInput('');
      form.setFieldValue(field.name, newTagList);
    }
  };

  const handleRemoveInput = (index: number) => {
    const updatedList = [...tagList];
    updatedList.splice(index, 1);
    setTagList(updatedList);
    form.setFieldValue(field.name, updatedList);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleAddInput();
    }
  };

  const isInvalid = useMemo(() => {
    let validate: boolean | undefined | string = false;
    if (!isEmpty(form) && field?.name) {
      const { touched, errors } = form;
      const touchedField = get(touched, field.name);
      const errorField = get(errors, field.name);
      //@ts-expect-error
      validate = touchedField && errorField;
    }
    if (typeof invalid === 'boolean') {
      validate = invalid;
    }
    return validate;
  }, [form, invalid, field]);

  const inputDefaultClass = 'input';
  const inputFocusClass = `focus:ring-${themeColor}-${primaryColorLevel} focus-within:ring-${themeColor}-${primaryColorLevel} focus-within:border-${themeColor}-${primaryColorLevel} focus:border-${themeColor}-${primaryColorLevel}`;
  const inputWrapperClass = `input-wrapper ${prefix || suffix ? className : ''}`;
  const inputClass = classNames(
    inputDefaultClass,
    !isInvalid && inputFocusClass,
    !prefix && !suffix ? className : '',
    disabled && 'input-disabled',
    isInvalid && 'input-invalid',
  );

  const prefixNode = useRef<HTMLDivElement | any>();
  const suffixNode = useRef<HTMLDivElement | any>();

  const getAffixSize = () => {
    if (!prefixNode.current && !suffixNode.current) {
      return;
    }
    const prefixNodeWidth = prefixNode?.current?.offsetWidth;
    const suffixNodeWidth = suffixNode?.current?.offsetWidth;

    if (isNil(prefixNodeWidth) && isNil(suffixNodeWidth)) {
      return;
    }

    if (prefixNodeWidth) {
      setPrefixGutter(prefixNodeWidth);
    }

    if (suffixNodeWidth) {
      setSuffixGutter(suffixNodeWidth);
    }
  };

  useEffect(() => {
    getAffixSize();
  }, [prefix, suffix]);

  const remToPxConvertion = (pixel: number) => 0.0625 * pixel;

  const affixGutterStyle = () => {
    const leftGutter = `${remToPxConvertion(prefixGutter) + 1}rem`;
    const rightGutter = `${remToPxConvertion(suffixGutter) + 1}rem`;
    const gutterStyle: { paddingLeft?: string; paddingRight?: string } = {};

    if (direction === 'ltr') {
      if (prefix) {
        gutterStyle.paddingLeft = leftGutter;
      }

      if (suffix) {
        gutterStyle.paddingRight = rightGutter;
      }
    }

    if (direction === 'rtl') {
      if (prefix) {
        gutterStyle.paddingRight = leftGutter;
      }

      if (suffix) {
        gutterStyle.paddingLeft = rightGutter;
      }
    }

    return gutterStyle;
  };

  const inputProps = {
    className: inputClass,
    disabled,
    type,
    ref,
  };

  const renderInput = (
    <div style={{ ...affixGutterStyle(), ...style }} {...inputProps}>
      <div className="flex">
        <input
          className={'input w-4/5'}
          type={type}
          value={currentInput}
          onChange={handleInputChange}
          placeholder={placeholder}
          onKeyDown={handleKeyPress}
        />
        <button onClick={handleAddInput} type="button" className="ml-2">
          <HiOutlinePlusCircle className="text-green-500" size={'30px'} />
        </button>
      </div>
      <div>
        {tagList.map((email, index) => (
          <Tag
            key={index}
            suffix={<HiX className="ml-1 rtl:mr-1 cursor-pointer" />}
            onClick={() => handleRemoveInput(index)}
            className="text-sm"
          >
            {email}
          </Tag>
        ))}
      </div>
    </div>
  );

  const renderAffixInput = (
    <span className={inputWrapperClass}>
      {prefix ? (
        <div className="input-suffix-start" ref={(node) => (prefixNode.current = node)}>
          {' '}
          {prefix}{' '}
        </div>
      ) : null}
      {renderInput}
      {suffix ? (
        <div className="input-suffix-end" ref={(node) => (suffixNode.current = node)}>
          {suffix}
        </div>
      ) : null}
    </span>
  );

  const renderChildren = () => {
    if (prefix || suffix) {
      return renderAffixInput;
    } else {
      return renderInput;
    }
  };

  return renderChildren();
};

export const MultiTextInputWithDisplay = forwardRef(MultiTextInputWithDisplayInner) as <T>(
  props: MultiTextInputWithDisplayProps<T> & { ref?: ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof MultiTextInputWithDisplayInner>;
