import { ChangeEvent, createContext } from 'react';

const RadioGroupContext = createContext<{
  name?: string;
  disabled?: boolean;
  value?: string | null;
  onChange: ((value: string | null, e: ChangeEvent<HTMLInputElement>) => void) | null;
  color?: string | null;
  vertical: boolean | null;
  radioGutter: number | null;
}>({ name: '', disabled: false, value: '', onChange: null, color: null, vertical: null, radioGutter: null });

export const RadioGroupContextProvider = RadioGroupContext.Provider;

export default RadioGroupContext;
