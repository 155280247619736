import { FunctionComponent, MouseEvent, useState } from 'react';
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi';
import { Input } from '../ui';
import { FieldInputProps } from 'formik';

export type PasswordInputProps = {
  field: FieldInputProps<unknown>;
  onVisibleChange?: (bool: boolean) => void;
};

export const PasswordInput: FunctionComponent<PasswordInputProps> = ({ onVisibleChange, ...otherProps }) => {
  const [pwInputType, setPwInputType] = useState('password');

  const onPasswordVisibleClick = (e: MouseEvent) => {
    e.preventDefault();
    const nextValue = pwInputType === 'password' ? 'text' : 'password';
    setPwInputType(nextValue);
    onVisibleChange?.(nextValue === 'text');
  };

  return (
    <Input
      {...otherProps}
      type={pwInputType}
      suffix={
        <span className="cursor-pointer text-xl" onClick={(e) => onPasswordVisibleClick(e)}>
          {pwInputType === 'password' ? <HiOutlineEyeOff /> : <HiOutlineEye />}
        </span>
      }
    />
  );
};
