import './App.css';
import './assets/styles/app.css';
import { FunctionComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { RootNavigator } from './navigators';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLocale } from './hooks';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const App: FunctionComponent = () => {
  const locale = useLocale();

  return (
    <BrowserRouter>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale} dateLibInstance={dayjs}>
          <RootNavigator />
        </LocalizationProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
