import { useMemo } from 'react';
import { Activity, Content as StockContent, WorkOrder } from 'src/types';
import { splitDateAndTime } from 'src/utils';

export type SectionTableDataProps =
  | {
      data: WorkOrder[];
      dataType: 'workOrder';
      splitBy?: 'StartDate';
    }
  | { data: Activity[]; dataType: 'activity'; splitBy?: 'Itinerant' }
  | { data: StockContent[]; dataType: 'stockContent'; splitBy?: 'Store' | 'Product' };

export type SectionTableDataSection =
  | (WorkOrder & {
      subRows: WorkOrder[];
    })
  | (Activity & { subRows: Activity[] })
  | (StockContent & { subRows: StockContent[] });
// export type GroupedTableDataSection<T extends WorkOrder> = T & { subRows: T[] };

export const useSectionTableData = ({
  data,
  dataType,
  splitBy,
}: SectionTableDataProps): { sections: SectionTableDataSection[] } => {
  const result = useMemo(() => {
    switch (dataType) {
      case 'workOrder': {
        switch (splitBy) {
          case 'StartDate':
          default: {
            const sortedByStartDateWorkOrders = [...data].sort((a, b) => {
              const dateA = new Date(a.StartDate);
              const dateB = new Date(b.StartDate);
              return dateB.getTime() - dateA.getTime();
            });
            const groupedWorkOrders = groupWorkOrdersByStartDate(sortedByStartDateWorkOrders);
            return groupedWorkOrders;
          }
        }
      }

      case 'activity': {
        const groupedActivities = groupActivitiesByItinerant(data);
        return groupedActivities;
      }

      case 'stockContent': {
        switch (splitBy) {
          case 'Store': {
            return groupStockContentByStore(data);
          }
          default: {
            return groupStockContentByStore(data);
          }
        }
      }

      default: {
        const groupedActivities = groupActivitiesByItinerant(data);
        return groupedActivities;
      }
    }
  }, [data, dataType, splitBy]);
  return { sections: result };
};

export const groupWorkOrdersByStartDate = (workOrders: WorkOrder[]) => {
  const formattedData: { [key: string]: any } = {};

  for (const workOrder of workOrders) {
    const { day: startDate } = splitDateAndTime(workOrder.StartDate);

    const blankWorkOrderData = {
      '@id': '',
      '@type': '',
      Client: { ...workOrder.Client, Name: '', City: '' },
      Status: '',
      Activities: [],
      CallerName: '',
      CustomerCallComment: '',
      InternalComment: '',
    };

    // If the formattedData object doesn't have a property for the startDate, create it
    if (!formattedData[startDate]) {
      formattedData[startDate] = {
        ...blankWorkOrderData,
        StartDate: startDate,
        subRows: [],
      };
    }

    // Create a new subRow object with the workOrder's properties and push it to the subRows array
    const subRow: WorkOrder = {
      ...workOrder,
    };
    formattedData[startDate].subRows.push(subRow);
  }

  const groupedData: SectionTableDataSection[] = Object.values(formattedData);

  return groupedData;
};

export const groupActivitiesByItinerant = (
  activities: Pick<Activity, 'StartDate' | 'EndDate' | 'Itinerant' | 'Type' | '@id'>[],
) => {
  const formattedData: { [key: string]: any } = {};

  for (const activity of activities) {
    const { time: startDate } = splitDateAndTime(activity.StartDate, { withoutSeconds: true });
    const { time: endDate } = splitDateAndTime(activity.EndDate, { withoutSeconds: true });

    const blankWorkOrderData = {
      Itinerant: activity.Itinerant,
    };

    const itinerantName = activity.Itinerant.PersistentName;

    // If the formattedData object doesn't have a property for the startDate, create it
    if (!formattedData[itinerantName]) {
      formattedData[itinerantName] = {
        ...blankWorkOrderData,
        subRows: [],
      };
    }

    // Create a new subRow object with the workOrder's properties and push it to the subRows array
    const subRow = {
      ...activity,
      StartDate: startDate,
      EndDate: endDate,
      // Itinerant: { '@id': '', PersistentName: '' },
    };
    formattedData[itinerantName].subRows.push(subRow);
  }

  const groupedData: SectionTableDataSection[] = Object.values(formattedData);

  return groupedData;
};

export const groupStockContentByStore = (stockContents: StockContent[]) => {
  const formattedData: { [key: string]: any } = {};

  for (const content of stockContents) {
    const store = { Name: `${content.Stock.Owner['@type']} - ${content.Stock.Owner.PersistentName}` };
    // const store = storeNameRandomizer();
    const blankStockContentData = {
      Store: store,
      Restock: null, // ne fonctionne pas car restock null = pas encore de restock donc null ne peut pas être utilisé pour neutraliser le switch
      // Store: content.Store,
    };

    // If the formattedData object doesn't have a property for the storeName, create it
    if (!formattedData[store.Name]) {
      formattedData[store.Name] = {
        ...blankStockContentData,
        subRows: [],
      };
    }

    // Create a new subRow object with the stockContent's properties and push it to the subRows array
    const subRow = {
      Store: store.Name,
      PartNumber: content.Product.PartNumber,
      Description: content.Product.Description,
      EndCategory: content.Product.EndCategory,
      ...content,
    };
    formattedData[store.Name].subRows.push(subRow);
  }

  const groupedData: SectionTableDataSection[] = Object.values(formattedData);

  return groupedData;
};
