import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { WorkOrder } from 'src/types';

type FormType = 'NEW' | 'EDIT';

type WorkOrderStateType = {
  drawerOpen: boolean;
  selectedWorkOrder: WorkOrder | null;
  formType: FormType | null;
};

const initialState: WorkOrderStateType = {
  drawerOpen: false,
  selectedWorkOrder: null,
  formType: null,
};

const workOrderStateSlice = createSlice({
  name: 'crmWorkOrders/state',
  initialState,
  reducers: {
    setSelectedWorkOrder: (state, action) => {
      state.selectedWorkOrder = action.payload;
    },
    setDrawerOpen: (state, action: PayloadAction<{ formType: FormType }>) => {
      state.drawerOpen = true;
      state.formType = action.payload.formType;
    },
    setDrawerClose: (state) => {
      state.drawerOpen = false;
      state.formType = null;
    },
  },
});

export const { setSelectedWorkOrder, setDrawerOpen, setDrawerClose } = workOrderStateSlice.actions;

export default workOrderStateSlice.reducer;
