import { CSSProperties, MouseEvent, PropsWithChildren, ReactNode, forwardRef, useCallback, useContext } from 'react';
import { DropdownItem } from './DropdownItem';
import { Menu } from './Menu';
import MenuContext from './context/menuContext';
import classNames from 'classnames';
import { ValueOf } from 'src/types';
import { Placement } from '../utils';

export type DropdownMenuProps = {
  onToggle?: (eventKey: string, event: MouseEvent) => void;
  onSelect?: (eventKey: string, event: MouseEvent) => void;
  activeKey?: string;
  hidden?: boolean;
  id?: string;
  eventKey: string;
  title?: string | ReactNode;
  className?: string;
  placement?: ValueOf<Placement>;
  icon?: ReactNode;
  trigger?: string;
  style?: CSSProperties;
};

export const DropdownMenu = forwardRef<HTMLUListElement, PropsWithChildren<DropdownMenuProps>>((props, ref) => {
  const { onToggle, eventKey, title, className, placement, ...rest } = props;

  const parentMenu = useContext(MenuContext);

  const handleToggleSubmenu = useCallback(
    (_: any, e: MouseEvent) => {
      onToggle?.(eventKey, e);
    },
    [eventKey, onToggle],
  );

  const dropdownMenuDefaultClass = `dropdown-menu`;
  const dropdownMenuPositionClass = placement;

  const dropdownMenuClass = classNames(dropdownMenuDefaultClass, dropdownMenuPositionClass, className);

  const dropdownSubmenuClass = classNames(dropdownMenuDefaultClass, 'dropdown-submenu');

  const dropdownSubmenu = (
    //@ts-expect-error
    <Menu className={dropdownSubmenuClass} ref={ref} onToggle={handleToggleSubmenu} placement={placement} {...rest} />
  );

  if (parentMenu) {
    const { icon, trigger } = props;
    const itemClassName = classNames(className);

    return (
      <DropdownItem
        icon={icon}
        trigger={trigger}
        className={itemClassName}
        subMenu={dropdownSubmenu}
        eventKey={eventKey}
      >
        {title}
      </DropdownItem>
    );
  }
  //@ts-expect-error
  return <Menu className={dropdownMenuClass} placement={placement} ref={ref} {...rest} />;
});
