import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { dateLocales } from 'src/assets/locales';

export const useLocale = () => {
  const { i18n } = useTranslation();

  const locale = i18n.resolvedLanguage;

  useEffect(() => {
    const formattedLang = locale?.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase();
    }) as keyof typeof dateLocales;
    if (locale !== i18n.language) {
      i18n.changeLanguage(formattedLang);
    }
    dateLocales[formattedLang]().then(() => {
      dayjs.locale(formattedLang);
    });
  }, [i18n, locale]);

  return locale;
};
