import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { MERCURE_TOKEN_COOKIE, TOKENS_COOKIE } from 'src/config/cookies';
import { DecodedToken, Tokens, mobystockApi, mobystockAuth } from 'src/services';
import { Profile, Role } from 'src/types';
import { addBreadcrumb, log, setUser } from 'src/utils';

export interface AuthState {
  isLoading: boolean;
  isSignedOut: boolean;
  tokens: Tokens | null;
  userRoles: Role[] | null;
  user: Profile | null;
  api: string;
  mercureToken: string | null;
}

const initialState: AuthState = {
  isLoading: true,
  isSignedOut: false,
  tokens: null,
  userRoles: null,
  user: null,
  api: 'api.mobystock.tech',
  mercureToken: null,
};

export const authenticateFromLocalStorage = createAsyncThunk<Tokens | null>(
  'auth/authenticateFromLocalStorage',
  async () => {
    const tokens = localStorage.getItem(TOKENS_COOKIE);
    return tokens ? JSON.parse(tokens) : null;
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signOut: (state) => {
      addBreadcrumb({
        category: 'auth',
        message: `Logged out user with userRoles=${state.userRoles?.join(', ')}`,
        level: 'info',
      });
      state.isLoading = false;
      state.isSignedOut = true;
      state.tokens = null;
      state.userRoles = null;
      state.user = null;
      localStorage.removeItem(TOKENS_COOKIE);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authenticateFromLocalStorage.fulfilled, (state, { payload }) => {
      const tokens = payload;
      if (tokens) {
        const decodedToken: DecodedToken = jwt_decode(tokens.token);
        state.userRoles = decodedToken.roles;
      }
      state.tokens = payload;
      state.isLoading = false;
      state.isSignedOut = false;
    });
    builder.addMatcher(mobystockAuth.endpoints.login.matchFulfilled, (state, { payload }) => {
      log('login.matchFulfilled', { payload });
      const tokens = payload;
      const decodedToken: DecodedToken = jwt_decode(tokens.token);
      state.isLoading = false;
      state.isSignedOut = false;
      state.tokens = tokens;
      state.userRoles = decodedToken.roles;
      state.mercureToken = decodedToken.mercure_token;
      state.api = decodedToken.api;
      localStorage.setItem(TOKENS_COOKIE, JSON.stringify(state.tokens));
      localStorage.setItem(MERCURE_TOKEN_COOKIE, decodedToken.mercure_token);
    });
    builder.addMatcher(mobystockAuth.endpoints.login.matchRejected, (state, { payload }) => {
      state.isLoading = false;
      state.isSignedOut = true;
      log('login.matchRejected', { payload });
    });
    builder.addMatcher(mobystockApi.endpoints.getProfile.matchFulfilled, (state, { payload }) => {
      log('getProfile.matchFulfilled', { payload });
      state.user = payload;
      const { '@id': id, CompleteName } = payload;
      setUser({ id, name: CompleteName });
    });
  },
});

export const { signOut } = authSlice.actions;

export const authSliceReducer = authSlice.reducer;
