import { KeyboardEvent, MouseEvent, Ref, useCallback, useEffect } from 'react';

const domContains = (context: any, node: Node | null) => {
  if (context.contains) {
    return context.contains(node);
  } else if (context.compareDocumentPosition) {
    return context === node || !!(context.compareDocumentPosition(node) & 16);
  }
  if (node) {
    do {
      if (node === context) {
        return true;
      }
    } while ((node = node.parentNode));
  }
  return false;
};

const getRefTarget = (ref: Ref<any>) => {
  return ref && ('current' in ref ? ref.current : ref);
};

function getDOMNode(elementOrRef: any) {
  return getRefTarget(elementOrRef);
}

// Function below was replaced by the one above because of the findDOMNode deprecation
// function getDOMNode(elementOrRef: any) {
//   const element = elementOrRef?.root || elementOrRef?.child || getRefTarget(elementOrRef);

//   if (element?.nodeType && typeof element?.nodeName === 'string') {
//     return element;
//   }

//   return findDOMNode(element);
// }

function isLeftClickEvent(e: MouseEvent) {
  return e?.button === 0;
}

function isModifiedEvent(e: KeyboardEvent) {
  return !!(e.metaKey || e.altKey || e.ctrlKey || e?.shiftKey);
}

function onEventListener(target: Element, eventType: any, listener: any, options = false) {
  target.addEventListener(eventType, listener, options);

  return {
    off() {
      target.removeEventListener(eventType, listener, options);
    },
  };
}

export const useRootClose = (
  onRootClose: (event: any) => void,
  {
    disabled,
    triggerTarget,
    overlayTarget,
  }: { disabled: boolean; triggerTarget: Ref<unknown>; overlayTarget: Ref<unknown>; listenEscape?: boolean },
) => {
  const handleDocumentMouseDown = useCallback(
    (event: any) => {
      const triggerElement = getDOMNode(triggerTarget);
      const overlayElement = getDOMNode(overlayTarget);

      if (triggerElement && domContains(triggerElement, event.target)) {
        return;
      }

      if (overlayElement && domContains(overlayElement, event.target)) {
        return;
      }

      if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
        return;
      }

      onRootClose?.(event);
    },
    [onRootClose, triggerTarget, overlayTarget],
  );

  useEffect(() => {
    const currentTarget = getDOMNode(triggerTarget);

    if (disabled || !currentTarget) return;

    const doc = () => (currentTarget && currentTarget.ownerDocument) || document;
    const onDocumentMouseDownListener = onEventListener(doc(), 'mousedown', handleDocumentMouseDown, true);

    return () => {
      onDocumentMouseDownListener?.off();
    };
  }, [triggerTarget, disabled, onRootClose, handleDocumentMouseDown]);
};
