import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import { ChangeEvent, PropsWithChildren, forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { CheckboxGroupContextProvider } from './context';

export type GroupProps = {
  value: unknown[];
  className?: string;
  onChange?: (val: unknown[], ev: ChangeEvent<HTMLInputElement>) => void;
  color: string;
  vertical?: boolean;
  name: string;
};

export const Group = forwardRef<HTMLDivElement, PropsWithChildren<GroupProps>>(
  ({ value: valueProp, className, onChange, color, vertical = false, name, children, ...rest }, ref) => {
    const [value, setValue] = useState(valueProp);

    const onCheckboxGroupChange = useCallback(
      (itemValue: unknown, itemChecked: boolean, event: ChangeEvent<HTMLInputElement>) => {
        const nextValue = cloneDeep(value) || [];
        if (itemChecked) {
          nextValue.push(itemValue);
        } else {
          remove(nextValue, (i) => shallowEqual(i, itemValue));
        }

        setValue(nextValue);
        onChange?.(nextValue, event);
      },
      [onChange, setValue, value],
    );

    useEffect(() => {
      setValue(valueProp);
    }, [valueProp]);

    const checkboxGroupDefaultClass = `inline-flex ${vertical ? 'flex-col gap-y-2' : ''}`;

    const checkBoxGroupClass = classNames(checkboxGroupDefaultClass, className);

    const contextValue = useMemo(
      () => ({
        vertical,
        name,
        value,
        color,
        onChange: onCheckboxGroupChange,
      }),
      [vertical, onCheckboxGroupChange, name, color, value],
    );

    return (
      <CheckboxGroupContextProvider value={contextValue}>
        <div ref={ref} className={checkBoxGroupClass} {...rest}>
          {children}
        </div>
      </CheckboxGroupContextProvider>
    );
  },
);
