import { BooleanPredicate, Item, ItemParams, Menu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';

export type ContextMenuItemParams<T> = ItemParams<T>;

export type ContextMenuConfig<T> = {
  menuId: string;
  menuItems: {
    title: string;
    id: string;
    isItemDisabled?: BooleanPredicate;
    action: ({ props }: ContextMenuItemParams<T>) => void;
  }[];
};

export type ContextMenuProps<T> = {
  contextMenuConfig: {
    menuId: string;
    menuItems: {
      title: string;
      id: string;
      isItemDisabled?: BooleanPredicate;
      action: ({ props }: ContextMenuItemParams<T>) => void;
    }[];
  };
};

export const ContextMenu = <T,>({ contextMenuConfig }: ContextMenuProps<T>) => {
  const { menuId, menuItems } = contextMenuConfig;
  return (
    <Menu id={menuId}>
      {menuItems.map((item) => (
        <Item key={item.id} id={item.id} onClick={item.action} disabled={item.isItemDisabled}>
          {item.title}
        </Item>
      ))}
    </Menu>
  );
};
