import classNames from 'classnames';
import { ElementType, MouseEvent, PropsWithChildren, StyleHTMLAttributes, forwardRef } from 'react';
import { ThemeState } from 'src/store';

export type MenuItemProps = {
  asElement?: ElementType;
  className?: string;
  disabled?: boolean;
  eventKey: string;
  isActive?: boolean;
  menuItemHeight?: number;
  onSelect?: (key: string, event: MouseEvent) => void;
  style?: StyleHTMLAttributes<HTMLDivElement>;
  variant?: ThemeState['navMode'];
  id?: string;
};

export const MenuItem = forwardRef<HTMLDivElement, PropsWithChildren<MenuItemProps>>(
  (
    {
      asElement: Component = 'div',
      children,
      className,
      disabled,
      eventKey,
      isActive,
      menuItemHeight = 35,
      onSelect,
      style,
      variant = 'light',
      ...otherProps
    },
    ref,
  ) => {
    const menuItemActiveClass = `menu-item-active`;
    const menuItemHoverClass = `menu-item-hoverable`;
    const disabledClass = 'menu-item-disabled';
    const menuItemClass = classNames(
      'menu-item',
      `menu-item-${variant}`,
      isActive && menuItemActiveClass,
      disabled && disabledClass,
      !disabled && menuItemHoverClass,
      className,
    );

    const hanldeOnClick = (e: MouseEvent) => {
      if (onSelect) {
        onSelect(eventKey, e);
      }
    };

    return (
      <Component
        ref={ref}
        className={menuItemClass}
        style={{ height: `${menuItemHeight}px`, ...style }}
        onClick={hanldeOnClick}
        {...otherProps}
      >
        {children}
      </Component>
    );
  },
);
