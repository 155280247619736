import { forwardRef, MouseEvent } from 'react';
import { HiX } from 'react-icons/hi';
import classNames from 'classnames';

export type CloseButtonProps = {
  absolute?: boolean;
  className?: string;
  defaultStyle?: boolean;
  svgClass?: string;
  onClick?: (e: MouseEvent) => void;
};

export const CloseButton = forwardRef<HTMLSpanElement, CloseButtonProps>(
  ({ absolute, className, defaultStyle = true, onClick, ...otherProps }, ref) => {
    const closeButtonAbsoluteClass = 'absolute z-10';

    const closeButtonClass = classNames(
      'close-btn',
      defaultStyle && 'close-btn-default',
      absolute && closeButtonAbsoluteClass,
      className,
    );

    return (
      <span className={closeButtonClass} role="button" ref={ref} onClick={onClick} {...otherProps}>
        <HiX />
      </span>
    );
  },
);
