import { FunctionComponent, PropsWithChildren, ReactNode, forwardRef } from 'react';
import classNames from 'classnames';
import { HiChevronDown, HiChevronUp, HiChevronRight, HiChevronLeft } from 'react-icons/hi';
import { Placement } from '../utils';
import { ValueOf } from 'src/types';

export type DropdownToggleDefaultContentProps = {
  placement?: ValueOf<Placement>;
};

const DropdownToggleDefaultContent: FunctionComponent<PropsWithChildren<DropdownToggleDefaultContentProps>> = ({
  placement,
  children,
}) => {
  // TODO: impl rtl handling
  if (placement && placement.includes('middle-start')) {
    return (
      <>
        {children}
        <HiChevronRight />
      </>
    );
  }

  if (placement && placement.includes('middle-end')) {
    return (
      <>
        <HiChevronLeft />
        {children}
      </>
    );
  }

  if (placement && placement.includes('top')) {
    return (
      <>
        {children}
        <HiChevronUp />
      </>
    );
  }

  return (
    <>
      {children}
      <HiChevronDown />
    </>
  );
};

export type DropdownToggleProps = {
  className?: string;
  renderTitle?: string | ReactNode;
  children?: string;
  placement?: ValueOf<Placement>;
  inSidenav?: string;
  disabled?: string;
  toggleClassName?: string;
  id?: string;
};

export const DropdownToggle = forwardRef<HTMLDivElement, PropsWithChildren<DropdownToggleProps>>(
  ({ className, renderTitle, children, placement, inSidenav, disabled, toggleClassName, ...otherProps }, ref) => {
    const toggleClass = 'dropdown-toggle';
    const disabledClass = 'dropdown-toggle-disabled';

    const dropdownToggleClass = classNames(toggleClass, className, toggleClassName, disabled && disabledClass);

    const dropdownToggleDefaultClass = classNames(dropdownToggleClass, 'dropdown-toggle-default');

    if (renderTitle) {
      return (
        <div className={dropdownToggleClass} {...otherProps} ref={ref}>
          {renderTitle}
        </div>
      );
    }

    return (
      <div ref={ref} className={dropdownToggleDefaultClass} {...otherProps}>
        <span className="flex items-center">
          <DropdownToggleDefaultContent placement={placement}>{children}</DropdownToggleDefaultContent>
        </span>
      </div>
    );
  },
);
