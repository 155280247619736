import classNames from 'classnames';
import { FunctionComponent, ReactNode } from 'react';
import { useConfig } from '../../ConfigProvider';
import { LAYOUT, Layout, SIZES, Size } from '../../utils/constant';
import { FormContextConsumer, FormContextProvider } from '../context';

export type FormContainerProps = {
  children: ReactNode;
  labelWidth?: number;
  layout?: Layout;
  size?: Size;
  className?: string;
};

export const FormContainer: FunctionComponent<FormContainerProps> = ({
  children,
  labelWidth = 100,
  layout = LAYOUT.VERTICAL,
  size = SIZES.MD,
  className,
}) => {
  const { controlSize } = useConfig();

  const contextValue = {
    labelWidth,
    layout,
    size: size || controlSize,
  };

  return (
    <FormContextProvider value={contextValue}>
      <FormContextConsumer>
        {(context) => {
          return <div className={classNames('form-container', context.layout, className)}>{children}</div>;
        }}
      </FormContextConsumer>
    </FormContextProvider>
  );
};
