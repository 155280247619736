import { ValueOf } from 'src/types';
import ToastWrapper, { ToastPlacement, ToastWrapperProps, toastDefaultProps } from './ToastWrapper';
import { ReactElement } from 'react';

const defaultWrapperId = 'default';
const wrappers = new Map();

const castPlacement = (placement: ValueOf<ToastPlacement>): 'top-full' | 'bottom-full' => {
  if (/\top\b/.test(placement)) {
    return 'top-full';
  }

  if (/\bottom\b/.test(placement)) {
    return 'bottom-full';
  }
  return 'bottom-full';
};

async function createWrapper(wrapperId: ValueOf<ToastPlacement> | string, props: ToastWrapperProps) {
  //@ts-expect-error
  const [wrapper] = await ToastWrapper.getInstance(props);

  wrappers.set(wrapperId || defaultWrapperId, wrapper);

  return wrapper;
}

function getWrapper(wrapperId: ValueOf<ToastPlacement> | string) {
  if (wrappers.size === 0) {
    return null;
  }
  return wrappers.get(wrapperId || defaultWrapperId);
}

const toast = (message: ReactElement) => toast.push(message);

toast.push = (message: ReactElement, options = toastDefaultProps) => {
  let id: ValueOf<ToastPlacement> | string = options.placement;
  if (options.block) {
    id = castPlacement(options.placement);
  }

  const wrapper = getWrapper(id);

  if (wrapper?.current) {
    return wrapper.current.push(message);
  }

  return createWrapper(id ?? '', options).then((ref) => {
    return ref.current?.push(message);
  });
};

toast.remove = (key: string) => {
  wrappers.forEach((elm) => elm.current.remove(key));
};

toast.removeAll = () => {
  wrappers.forEach((elm) => elm.current.removeAll());
};

export default toast;
