import classNames from 'classnames';
import { ElementType, PropsWithChildren, forwardRef } from 'react';

export type TrProps = { className?: string; asElement?: ElementType; onClick?: () => void };

export const Tr = forwardRef<HTMLElement, PropsWithChildren<TrProps>>(
  ({ children, asElement: Component = 'tr', className, ...otherProps }, ref) => {
    const trClass = classNames(Component !== 'tr' && 'tr', className);

    return (
      <Component className={trClass} ref={ref} {...otherProps}>
        {children}
      </Component>
    );
  },
);
