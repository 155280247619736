import classNames from 'classnames';
import { ReactNode, forwardRef } from 'react';
import { useConfig } from '../ConfigProvider';
import { useCallbackRef } from '../hooks';
import { useTabs } from './context';

export type TabNavProps = {
  value: string;
  disabled?: boolean;
  className?: string;
  icon?: string | ReactNode;
  children: ReactNode;
};

export const TabNav = forwardRef<HTMLDivElement, TabNavProps>(
  ({ value: valueProp, disabled, className, icon, children, ...rest }, ref) => {
    const { value, onValueChange, variant } = useTabs();
    const isSelected = valueProp === value;

    const { themeColor, primaryColorLevel } = useConfig();

    const onTabNavClick = useCallbackRef(() => {
      if (!isSelected && !disabled) {
        onValueChange?.(valueProp);
      }
    });

    const color = `${themeColor}-${primaryColorLevel}`;

    const tabNavClass = classNames(
      'tab-nav',
      `tab-nav-${variant}`,
      isSelected && `tab-nav-active text-${color} dark:text-${themeColor}-100`,
      isSelected && variant === 'underline' && `border-${color}`,
      isSelected && variant === 'pill' && `bg-${themeColor}-50 dark:bg-${color} dark:text-gray-100`,
      disabled && 'tab-nav-disabled',
      !disabled && !isSelected && `hover:text-${color} dark:hover:text-${themeColor}-100`,
      className,
    );

    return (
      <div className={tabNavClass} role="tab" aria-selected={isSelected} ref={ref} onClick={onTabNavClick} {...rest}>
        {icon && <div className="tab-nav-icon">{icon}</div>}
        {children}
      </div>
    );
  },
);
