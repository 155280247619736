import {
  Cell,
  ColumnDef,
  RowData,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import {
  ChangeEvent,
  ForwardedRef,
  FunctionComponent,
  MouseEvent,
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useContextMenu } from 'react-contexify';
import {
  Alert,
  Checkbox,
  ContextMenu,
  ContextMenuConfig,
  Pagination,
  PagingData,
  Select,
  Table,
} from 'src/components/ui';
import { IS_DEV } from 'src/config/env';
import { Loading } from './Loading';
import { TableRowSkeleton, TableRowSkeletonProps } from './loaders';

const { Tr, Th, Td, THead, TBody, Sorter } = Table;

export type IndeterminateCheckboxProps = {
  indeterminate: unknown;
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  onCheckBoxChange?: (ev: ChangeEvent<HTMLInputElement>) => void;
  onIndeterminateCheckBoxChange?: (ev: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  disabled?: boolean;
};
export const IndeterminateCheckbox: FunctionComponent<IndeterminateCheckboxProps> = ({
  indeterminate,
  onChange,
  onCheckBoxChange,
  onIndeterminateCheckBoxChange,
  ...otherProps
}) => {
  const ref = useRef<{ indeterminate: boolean } & HTMLInputElement>(null);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current!.indeterminate = !otherProps.checked && indeterminate;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, indeterminate]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    onCheckBoxChange?.(e);
    onIndeterminateCheckBoxChange?.(e);
  };

  return <Checkbox className="mb-0" ref={ref} onChange={(_, e) => handleChange(e)} {...otherProps} />;
};

export type DataTableProps<T, U> = {
  data?: T[];
  columns: ColumnDef<U>[];
  skeletonAvatarColumns: number[];
  loading: boolean;
  onCheckBoxChange?: (checked: boolean, row: unknown) => void;
  onIndeterminateCheckBoxChange?: (checked: boolean, rows: unknown) => void;
  onPaginationChange?: (pageIndex: number) => void;
  onSelectChange?: (pageSize: number) => void;
  onSort?: (sort: PagingData['sort']) => void;
  pageSizes?: number[];
  selectable?: boolean;
  skeletonAvatarProps?: TableRowSkeletonProps['avatarProps'];
  pagingData?: PagingData;
  contextMenuConfig?: ContextMenuConfig<T>;
  centeredHeaders?: boolean;
  centeredCells?: boolean;
};

const DataTableInner = <T, U>(
  {
    skeletonAvatarColumns,
    columns: columnsProp = [],
    data = [],
    loading = false,
    onCheckBoxChange,
    onIndeterminateCheckBoxChange,
    onPaginationChange,
    onSelectChange,
    onSort,
    pageSizes = [10, 25, 50, 100],
    selectable = false,
    skeletonAvatarProps,
    pagingData = {
      total: 0,
      pageIndex: 1,
      pageSize: 25,
      sort: { key: '', order: 'asc' },
    },
    contextMenuConfig,
    centeredHeaders,
    centeredCells,
  }: DataTableProps<T, U>,
  ref: Ref<unknown>,
) => {
  const { show } = useContextMenu({
    id: contextMenuConfig?.menuId || 'menu-id',
  });

  const { pageIndex, pageSize: initialPageSize, total } = pagingData;

  const [pageSize, setPageSize] = useState(initialPageSize);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [expandedCellText, setExpandedCellText] = useState<RowData | null>(null);

  const pageSizeOption = useMemo(
    () =>
      pageSizes?.map((number) => ({
        value: number,
        label: `${number} / page`,
      })),
    [pageSizes],
  );

  const handleCheckBoxChange = (checked: boolean, row: unknown) => {
    if (!loading) {
      onCheckBoxChange?.(checked, row);
    }
  };

  const handleIndeterminateCheckBoxChange = (checked: boolean, rows: unknown) => {
    if (!loading) {
      onIndeterminateCheckBoxChange?.(checked, rows);
    }
  };

  const handlePaginationChange = (page: number | string) => {
    const cleanPage = typeof page === 'number' ? page : Number(page);
    if (!loading) {
      onPaginationChange?.(cleanPage);
    }
  };

  const handleSelectChange = (value: number) => {
    if (!loading) {
      setPageSize(Number(value));
      onSelectChange?.(Number(value));
    }
  };

  useEffect(() => {
    if (Array.isArray(sorting)) {
      const sortOrder = sorting.length > 0 ? (sorting[0].desc ? 'desc' : 'asc') : 'desc';
      const id = sorting.length > 0 ? sorting[0].id : '';
      onSort?.({ order: sortOrder, key: id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  // @ts-expect-error
  const hasOldColumnMetaKey = columnsProp.some((col) => col.Header || col.accessor || col.Cell);

  const finalColumns = useMemo(() => {
    const columns = columnsProp;

    if (selectable) {
      const selectableColumns: ColumnDef<U>[] = [
        {
          id: 'select',
          header: ({ table }) => (
            <IndeterminateCheckbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
              onIndeterminateCheckBoxChange={(e) => {
                handleIndeterminateCheckBoxChange(e.target.checked, table.getRowModel().rows);
              }}
            />
          ),
          cell: ({ row }) => (
            <IndeterminateCheckbox
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
              onCheckBoxChange={(e) => handleCheckBoxChange(e.target.checked, row.original)}
            />
          ),
        },
        ...columns,
      ];
      return selectableColumns;
    }
    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsProp, selectable]);

  const table = useReactTable({
    data,
    //@ts-expect-error
    columns: hasOldColumnMetaKey ? [] : finalColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const resetSorting = () => {
    table.resetSorting();
  };

  const resetSelected = () => {
    table.toggleAllRowsSelected(false);
  };

  useImperativeHandle(ref, () => ({
    resetSorting,
    resetSelected,
  }));

  if (hasOldColumnMetaKey) {
    const message =
      'You are using old react-table v7 column config, please use v8 column config instead, refer to our demo or https://tanstack.com/table/v8';

    if (IS_DEV) {
      console.warn(message);
    }

    return <Alert>{message}</Alert>;
  }

  const handleCellClick = (row: RowData, cell: Cell<T, unknown>) => {
    if (cell.id.includes('action')) return;
    if (expandedCellText === row) {
      setExpandedCellText(null);
    } else {
      setExpandedCellText(row);
    }
  };

  return (
    <Loading loading={loading && data.length !== 0} type="cover">
      <Table compact>
        <THead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        className={classNames(
                          header.column.getCanSort() && 'point cursor-pointer select-none',
                          loading && 'pointer-events-none',
                          centeredHeaders && 'text-center',
                        )}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getCanSort() && <Sorter sort={header.column.getIsSorted()} />}
                      </div>
                    )}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </THead>
        {loading && data.length === 0 ? (
          <TableRowSkeleton
            columns={finalColumns.length}
            rows={pagingData.pageSize}
            avatarInColumns={skeletonAvatarColumns}
            avatarProps={skeletonAvatarProps}
          />
        ) : (
          <TBody>
            {table
              .getRowModel()
              .rows.slice(0, pageSize)
              .map((row) => {
                const isTextExpanded = row.original === expandedCellText;
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      function displayMenu(e: MouseEvent<HTMLTableCellElement>) {
                        show({
                          event: e,
                          props: row.original,
                        });
                      }
                      return (
                        <Td
                          onClick={() => handleCellClick(row.original, cell)}
                          key={cell.id}
                          className={classNames(
                            'max-w-[100px] cursor-pointer',
                            !isTextExpanded && 'overflow-hidden overflow-ellipsis whitespace-nowrap',
                            centeredCells && 'text-center',
                          )}
                          onContextMenu={displayMenu}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </TBody>
        )}
      </Table>
      <div className="mt-4 flex items-center justify-between">
        <Pagination pageSize={pageSize} currentPage={pageIndex} total={total} onChange={handlePaginationChange} />
        <div style={{ minWidth: 130 }}>
          <Select
            size="sm"
            menuPlacement="top"
            isSearchable={false}
            value={pageSizeOption.filter((option) => option.value === pageSize)}
            options={pageSizeOption}
            // @ts-expect-error
            onChange={(option) => handleSelectChange(option.value)}
          />
        </div>
      </div>
      {contextMenuConfig && <ContextMenu contextMenuConfig={contextMenuConfig} />}
    </Loading>
  );
};

export const DataTable = forwardRef(DataTableInner) as <T, U>(
  props: DataTableProps<T, U> & { ref?: ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof DataTableInner>;
