import { createContext, useContext } from 'react';
import { Size } from '../utils';

const FormContext = createContext<{
  labelWidth: number;
  layout: string;
  size: Size;
}>({
  labelWidth: 0,
  layout: '',
  size: 'md',
});

export const FormContextProvider = FormContext.Provider;

export const FormContextConsumer = FormContext.Consumer;

export function useForm() {
  return useContext(FormContext);
}

export default FormContext;
