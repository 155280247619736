import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { useConfig } from '../ConfigProvider';

export const Sorter = ({ sort }: { sort: boolean | 'asc' | 'desc' }) => {
  const { themeColor, primaryColorLevel } = useConfig();

  const color = `text-${themeColor}-${primaryColorLevel}`;

  const renderSort = () => {
    if (typeof sort === 'boolean' && !sort) {
      return <FaSort />;
    }

    if (typeof sort === 'string' && sort === 'asc') {
      return <FaSortDown className={color} />;
    }

    if (typeof sort === 'string' && sort === 'desc') {
      return <FaSortUp className={color} />;
    }
  };

  return <div className="inline-flex">{renderSort()}</div>;
};
