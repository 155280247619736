import { useRef, useCallback, forwardRef, PropsWithChildren, MouseEvent, ReactNode, CSSProperties } from 'react';
import DropdownMenuContext, { useDropdownMenuContext } from './context/dropdownMenuContext';
import { useUniqueId } from '../hooks';
import { useRootClose } from '../hooks/useRootClose';
import { arrayIndexOf } from '../utils/arrayIndexOf';
import { PLACEMENT, Placement, chainedFunction } from '../utils';
import { DropdownToggle } from './DropdownToggle';
import { ValueOf } from 'src/types';
import DropdownContext from './context/dropdownContext';
import { DropdownMenu } from './DropdownMenu';

const CLICK = 'click';
const HOVER = 'hover';
const CONTEXT = 'context';

export type DropdownProps = {
  title?: string;
  className?: string;
  menuClass?: string;
  menuStyle?: CSSProperties;
  disabled?: string;
  renderTitle?: ReactNode;
  placement?: ValueOf<Placement>;
  activeKey?: string;
  toggleClassName?: string;
  trigger?: 'click' | 'hover' | 'context';
  style?: CSSProperties;
  onClick?: (event: MouseEvent) => void;
  onMouseEnter?: (event: MouseEvent) => void;
  onMouseLeave?: (event: MouseEvent) => void;
  onContextMenu?: (arg?: unknown) => void;
  onSelect?: (eventKey: string, e: MouseEvent) => void;
  onOpen?: () => void;
  onClose?: () => void;
  onToggle?: (bool: boolean) => void;
};

export const Dropdown = forwardRef<HTMLDivElement, PropsWithChildren<DropdownProps>>(
  (
    {
      title,
      children,
      className,
      menuClass,
      menuStyle,
      disabled,
      renderTitle,
      placement = PLACEMENT.BOTTOM_START,
      activeKey,
      toggleClassName,
      trigger = 'click',
      style,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onContextMenu,
      onSelect,
      onOpen,
      onClose,
      onToggle,
      ...otherProps
    },
    ref,
  ) => {
    const overlayTarget = useRef<HTMLUListElement>(null);
    const triggerTarget = useRef<HTMLDivElement>(null);
    const menuControl = useDropdownMenuContext(overlayTarget);
    const open = menuControl.open;

    const buttonId = useUniqueId('dropdown-toggle-');
    const menuId = useUniqueId('base-menu-');

    const handleToggle = useCallback(
      (isOpen?: boolean) => {
        const nextOpen = typeof isOpen === 'undefined' ? !open : isOpen;
        const fn = nextOpen ? onOpen : onClose;

        fn?.();
        onToggle?.(nextOpen);
        if (nextOpen) {
          menuControl.openMenu();
        } else {
          menuControl.closeMenu();
        }
      },
      [onClose, onOpen, onToggle, open, menuControl],
    );

    const handleClick = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        if (disabled) {
          return;
        }
        handleToggle();
      },
      [disabled, handleToggle],
    );

    const handleMouseEnter = useCallback(() => {
      if (!disabled) {
        handleToggle(true);
      }
    }, [disabled, handleToggle]);

    const handleMouseLeave = useCallback(() => {
      if (!disabled) {
        handleToggle(false);
      }
    }, [disabled, handleToggle]);

    const handleSelect = (eventKey: string, e: MouseEvent) => {
      onSelect?.(eventKey, e);
      handleToggle(false);
    };

    useRootClose(() => handleToggle(), {
      triggerTarget,
      overlayTarget,
      disabled: !open,
      listenEscape: false,
    });

    const dropdownProps = {
      onMouseEnter,
      onMouseLeave,
    };

    const toggleEventHandlers = {
      onClick: onClick,
      onContextMenu,
    };

    if (arrayIndexOf(CLICK, trigger)) {
      toggleEventHandlers.onClick = chainedFunction(handleClick, toggleEventHandlers.onClick);
    }

    if (arrayIndexOf(CONTEXT, trigger)) {
      toggleEventHandlers.onContextMenu = chainedFunction(handleClick, onContextMenu);
    }

    if (arrayIndexOf(HOVER, trigger)) {
      dropdownProps.onMouseEnter = chainedFunction(handleMouseEnter, onMouseEnter);
      dropdownProps.onMouseLeave = chainedFunction(handleMouseLeave, onMouseLeave);
    }

    const toggleElement = (
      <DropdownToggle
        {...otherProps}
        {...toggleEventHandlers}
        id={buttonId}
        ref={triggerTarget}
        className={toggleClassName}
        renderTitle={renderTitle}
        disabled={disabled}
        placement={placement}
      >
        {title}
      </DropdownToggle>
    );

    const menuElement = (
      //@ts-expect-error
      <DropdownMenu
        className={menuClass}
        style={menuStyle}
        onSelect={handleSelect}
        activeKey={activeKey}
        ref={overlayTarget}
        hidden={!open}
        placement={placement}
        id={menuId}
      >
        {children}
      </DropdownMenu>
    );

    return (
      <DropdownContext.Provider value={{ activeKey } as { activeKey: string }}>
        <div {...dropdownProps} ref={ref} style={style} className="dropdown">
          {toggleElement}
          <DropdownMenuContext.Provider value={menuControl}>{menuElement}</DropdownMenuContext.Provider>
        </div>
      </DropdownContext.Provider>
    );
  },
);
