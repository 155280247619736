import { FunctionComponent, PropsWithChildren, ReactNode, cloneElement } from 'react';
import { Card, Container, Logo } from 'src/components';

export type PublicLayoutProps = { content?: ReactNode };

export const PublicLayout: FunctionComponent<PropsWithChildren<PublicLayoutProps>> = ({
  children,
  content,
  ...otherProps
}) => {
  return (
    <div className="h-screen" {...otherProps}>
      <Container className="flex h-full min-w-0 flex-auto flex-col items-center justify-center">
        <Card className="w-full lg:w-1/2" bodyClass="md:p-10">
          <div className="text-center flex justify-center">
            <Logo type="streamline" imgClass="mx-auto rounded-xl" logoWidth={200} />
          </div>
          <div className="text-center min-w-[320px]">
            {content}
            {children
              ? //@ts-expect-error
                cloneElement(children, {
                  contentClassName: 'text-center',
                  ...otherProps,
                })
              : null}
          </div>
        </Card>
      </Container>
    </div>
  );
};
