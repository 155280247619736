import { FieldInputProps, FormikProps } from 'formik';
import { CSSProperties, ForwardedRef, forwardRef } from 'react';
import { useGetItinerantsQuery } from 'src/services';
import { Alert, Select, SelectOption, SelectProps } from '../ui';
import { Loading } from './Loading';

export type ItinerantSelectProps<T extends Record<string, unknown>> = SelectProps & {
  field: FieldInputProps<T>;
  form: FormikProps<T>;
  style?: CSSProperties;
};

const ItinerantSelectInner = <T extends Record<string, unknown>>(
  { field, size = 'sm', form, placeholder }: ItinerantSelectProps<T>,
  ref: ForwardedRef<HTMLElement>,
) => {
  const { data: itinerantsData, isLoading, isError } = useGetItinerantsQuery();

  const defaultValue =
    Array.isArray(field.value) &&
    field.value.map((value) => {
      return { label: value.PersistentName, value: value['@id'] };
    });

  const onChange = (option: SelectOption[]) => {
    const itinerantsInfo = option.map((option) => {
      const itinerant = itinerantsData?.find((itinerant) => itinerant['@id'] === option.value);
      return itinerant;
    });
    form.setFieldValue(field.name, itinerantsInfo);
  };

  const Options = itinerantsData?.map((itinerant) => {
    return { label: itinerant.PersistentName, value: itinerant['@id'] };
  });

  if (isLoading) {
    return <Loading loading={true} />;
  }

  return (
    <>
      <Select
        size={size}
        ref={ref}
        options={Options}
        isMulti
        defaultValue={defaultValue}
        placeholder={placeholder}
        //@ts-expect-error
        onChange={onChange}
      />
      {isError && <Alert>Unable to retrieve itinerants.</Alert>}
    </>
  );
};

export const ItinerantSelect = forwardRef(ItinerantSelectInner);
