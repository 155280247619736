import classNames from 'classnames';
import { ElementType, forwardRef, PropsWithChildren } from 'react';

export type TBodyProps = { className?: string; asElement?: ElementType };

export const TBody = forwardRef<HTMLElement, PropsWithChildren<TBodyProps>>(
  ({ children, className, asElement: Component = 'tbody', ...otherProps }, ref) => {
    const tBodyClass = classNames(Component !== 'tbody' && 'tbody', className);

    return (
      <Component className={tBodyClass} {...otherProps} ref={ref}>
        {children}
      </Component>
    );
  },
);
