import classNames from 'classnames';
import { ChangeEvent, PropsWithChildren, forwardRef, useCallback, useMemo, useState } from 'react';
import { NAV_MODE_ENUM } from 'src/config/theme';
import { ValueOf } from 'src/types';
import { RadioGroupContextProvider } from './context';

export type GroupProps = {
  color?: string;
  disabled?: boolean;
  name?: string;
  onChange?: <T extends ValueOf<typeof NAV_MODE_ENUM> | null>(val: T, e: ChangeEvent<HTMLInputElement>) => void;
  radioGutter?: number;
  value?: string | null;
  vertical?: boolean;
  className?: string;
  id?: string;
};

export const Group = forwardRef<HTMLDivElement, PropsWithChildren<GroupProps>>(
  (
    {
      color,
      disabled,
      name,
      onChange,
      radioGutter = 3,
      value: valueProp,
      vertical = false,
      className,
      id,
      children,
      ...otherProps
    },
    ref,
  ) => {
    const [value, setValue] = useState(valueProp);

    const onRadioGroupChange = useCallback(
      (nextValue: any, e: ChangeEvent<HTMLInputElement>) => {
        setValue(nextValue);
        onChange?.(nextValue, e);
      },
      [onChange, setValue],
    );

    const contextValue = useMemo(
      () => ({
        vertical,
        name,
        value: typeof value === 'undefined' ? null : value,
        color,
        disabled,
        radioGutter,
        onChange: onRadioGroupChange,
      }),
      [disabled, onRadioGroupChange, vertical, name, color, radioGutter, value],
    );

    const radioGroupClass = classNames('radio-group', vertical && 'vertical', className);

    const groupChild = () => {
      return (
        <div id={id} ref={ref} className={radioGroupClass} {...otherProps}>
          {children}
        </div>
      );
    };

    return <RadioGroupContextProvider value={contextValue}>{groupChild()}</RadioGroupContextProvider>;
  },
);
