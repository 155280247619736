import classNames from 'classnames';
import { motion } from 'framer-motion';
import { FunctionComponent, MouseEvent, ReactNode, useContext, useEffect, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { useConfig } from '../ConfigProvider';
import { CollapseContextProvider } from './context/collapseContext';
import MenuContext from './context/menuContext';

export type MenuCollapse = {
  className?: string;
  eventKey: string;
  expanded: boolean;
  label?: ReactNode | null;
  onToggle?: (bool: boolean, event: MouseEvent) => void;
  children: ReactNode[];
};

export const MenuCollapse: FunctionComponent<MenuCollapse> = ({
  children,
  className,
  eventKey,
  expanded = false,
  label = null,
  onToggle,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded);

  const { menuItemHeight, variant, sideCollapsed, defaultExpandedKeys } = useContext(MenuContext);

  const { direction } = useConfig();

  useEffect(() => {
    if (defaultExpandedKeys?.includes(eventKey)) {
      setIsExpanded(true);
    }
    if (expanded !== isExpanded) {
      setIsExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, onToggle, eventKey, defaultExpandedKeys]);

  const toggleCollapse = (e: MouseEvent) => {
    if (typeof onToggle === 'function') {
      onToggle?.(!isExpanded, e);
    }
    setIsExpanded(!isExpanded);
  };

  const getChildrenHeight = () => {
    let height = 0;
    if (isExpanded && children && children.length) {
      height = children.length * menuItemHeight;
    }
    if (isExpanded && children && !children.length) {
      height = menuItemHeight;
    }
    return height;
  };

  const menuCollapseItemClass = classNames('menu-collapse-item', `menu-collapse-item-${variant}`, className);

  return (
    <div className="menu-collapse">
      <div className={menuCollapseItemClass} onClick={toggleCollapse}>
        <span className="flex items-center">{label}</span>
        <motion.span
          className="mt-1 text-lg"
          initial={{ transform: 'rotate(0deg)' }}
          animate={{
            transform: isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)',
          }}
          transition={{ duration: 0.15 }}
        >
          {sideCollapsed ? null : <HiChevronDown />}
        </motion.span>
      </div>
      <CollapseContextProvider value={isExpanded}>
        <motion.ul
          className={direction === 'rtl' ? 'mr-5' : 'ml-5'}
          initial={{ opacity: 0, height: 0, overflow: 'hidden' }}
          animate={{
            opacity: isExpanded ? 1 : 0,
            height: isExpanded ? getChildrenHeight() : 0,
          }}
          transition={{ duration: 0.15 }}
        >
          {children}
        </motion.ul>
      </CollapseContextProvider>
    </div>
  );
};
