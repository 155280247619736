import classNames from 'classnames';
import { ElementType, PropsWithChildren, forwardRef } from 'react';

export type TFootProps = { className?: string; asElement?: ElementType };

export const TFoot = forwardRef<HTMLElement, PropsWithChildren<TFootProps>>(
  ({ children, className, asElement: Component = 'tfoot', ...otherProps }, ref) => {
    const tBodyClass = classNames(Component !== 'tfoot' && 'tfoot', className);

    return (
      <Component className={tBodyClass} {...otherProps} ref={ref}>
        {children}
      </Component>
    );
  },
);
