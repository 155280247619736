import { createContext } from 'react';

export type DropdownContextType = { activeKey: string } | null;

const DropdownContext = createContext<DropdownContextType>(null);

export const DropdownContextProvider = DropdownContext.Provider;

export const DropdownContextConsumer = DropdownContext.Consumer;

export default DropdownContext;
