import { createContext, useContext } from 'react';
import { Size } from '../utils';

const InputGroupContext = createContext<{ size: Size | null }>({ size: null });

export const InputGroupContextProvider = InputGroupContext.Provider;

export const InputGroupContextConsumer = InputGroupContext.Consumer;

export function useInputGroup() {
  return useContext(InputGroupContext);
}

export default InputGroupContext;
