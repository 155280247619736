import * as Sentry from '@sentry/react';
import { FunctionComponent } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PageContainer } from 'src/components';
import { PrivateLayout } from 'src/layouts';
import { Error } from 'src/views';
import { protectedRoutes } from './routes';
import { AppRoute } from './AppRoute';

export const PrivateNavigator: FunctionComponent = (props) => {
  // const routes = (
  // <Routes>
  //   <Route index element={<Navigate to="/clients" replace />} />
  //   <Route path="/clients" element={<Clients />} />
  //   <Route path="*" element={<Navigate to="/clients" replace />} />
  //   {/* <Route path="/itinerants" element={<ItinerantList />} /> */}
  //   {/* <Route path="/500" element={<ErrorScreen />} />
  //     <Route path="*" element={<NotFoundScreen />} /> */}
  // </Routes>;
  // );
  return (
    <PrivateLayout>
      <Sentry.ErrorBoundary
        fallback={(props) => <Error {...props} />}
        beforeCapture={(scope) => {
          scope.setTag('navigator', 'private');
        }}
      >
        <Routes>
          <Route index element={<Navigate to="/wip" replace />} />
          {protectedRoutes.map((route, index) => (
            <Route
              key={route.key + index}
              path={route.path}
              element={
                <PageContainer {...props} {...route.meta}>
                  <AppRoute routeKey={route.key} component={route.component} {...route.meta} />
                </PageContainer>
              }
            />
          ))}
          <Route path="*" element={<Navigate to="/planning" replace />} />
        </Routes>
      </Sentry.ErrorBoundary>
    </PrivateLayout>
  );
};
