import { ChangeEvent, createContext } from 'react';

export type CheckboxGroupContextType = {
  name: string | null;
  value: unknown[] | null;
  onChange: ((val: unknown, bool: boolean, ev: ChangeEvent<HTMLInputElement>) => void) | null;
  color: string | null;
};

const CheckboxGroupContext = createContext<CheckboxGroupContextType>({
  name: null,
  value: null,
  onChange: null,
  color: null,
});

export const CheckboxGroupContextProvider = CheckboxGroupContext.Provider;

export default CheckboxGroupContext;
