import classNames from 'classnames';
import { FieldInputProps, FormikProps } from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { ElementType, FunctionComponent, forwardRef } from 'react';
import { HiCheck, HiChevronDown, HiX } from 'react-icons/hi';
import ReactSelect, {
  Props as BaseSelectProps,
  ClearIndicatorProps,
  ControlProps,
  StylesConfig,
  Theme,
} from 'react-select';
import tw, { theme } from 'twin.macro';
import { useConfig } from '../ConfigProvider';
import { useForm } from '../Form/context';
import { useInputGroup } from '../InputGroup/context';
import { Spinner } from '../Spinner';
import { CONTROL_SIZES, Size } from '../utils/constant';

type DefaultOptionProps = {
  innerProps: {};
  label: string;
  selectProps: { themeColor: string };
  isSelected: boolean;
  isDisabled: boolean;
  isFocused: boolean;
};

export type SelectOption = { label: string; value: string };

const DefaultOption: FunctionComponent<DefaultOptionProps> = ({
  innerProps,
  label,
  selectProps,
  isSelected,
  isDisabled,
  isFocused,
}) => {
  const { themeColor } = selectProps;
  return (
    <div
      className={classNames(
        'select-option',
        isSelected && 'selected',
        isDisabled && 'disabled',
        isFocused && 'focused',
      )}
      {...innerProps}
    >
      <span className="ml-2">{label}</span>
      {isSelected && <HiCheck className={`text-${themeColor} text-xl dark:text-white`} />}
    </div>
  );
};

const DefaultDropdownIndicator = () => {
  return (
    <div className="select-dropdown-indicator">
      <HiChevronDown />
    </div>
  );
};

const DefaultClearIndicator = ({ innerProps: { ref, ...restInnerProps } }: ClearIndicatorProps) => {
  return (
    <div {...restInnerProps} ref={ref}>
      <div className="select-clear-indicator">
        <HiX />
      </div>
    </div>
  );
};

const DefaultLoadingIndicator: FunctionComponent<{ selectProps: { themeColor: string } }> = ({
  selectProps: { themeColor },
}) => {
  return <Spinner className={`select-loading-indicatior text-${themeColor}`} />;
};

export type SelectProps = {
  size: Size;
  style?: StylesConfig;
  className?: string;
  form?: FormikProps<unknown>;
  field?: FieldInputProps<ReactSelect>;
  componentAs?: ElementType;
  onChange?: (newValue: SelectOption) => void;
} & Partial<Omit<BaseSelectProps, 'onChange'>>;

export const Select = forwardRef<HTMLElement, SelectProps>(
  ({ size, style, className, form, field, components, componentAs: Component = ReactSelect, ...rest }, ref) => {
    const { themeColor, controlSize, primaryColorLevel, mode } = useConfig();
    const formControlSize = useForm()?.size;
    const inputGroupSize = useInputGroup()?.size;
    const selectSize = size || inputGroupSize || formControlSize || controlSize;

    const twColor = theme`colors` as Record<string, string>;
    const twHeight = theme`height`;

    let isInvalid = false;

    if (form && field && !isEmpty(form)) {
      const { touched, errors } = form;

      const touchedField = get(touched, field.name);
      const errorField = get(errors, field.name);

      isInvalid = touchedField && errorField;
    }

    const getBoxShadow = (state: ControlProps) => {
      const shadowBase = '0 0 0 1px ';

      if (isInvalid) {
        return shadowBase + twColor.red['500'];
      }

      if (state.isFocused) {
        return shadowBase + twColor[themeColor][primaryColorLevel];
      }

      return 'none';
    };

    const styles: StylesConfig = {
      control: (provided, state) => {
        return {
          ...provided,
          height: twHeight[CONTROL_SIZES[selectSize]],
          minHeight: twHeight[CONTROL_SIZES[selectSize]],
          '&:hover': {
            boxShadow: getBoxShadow(state),
            cursor: 'pointer',
          },
          boxShadow: getBoxShadow(state),
          borderRadius: tw`rounded-md`.borderRadius as string,
          ...(isInvalid ? { borderColor: twColor.red['500'] } : {}),
        };
      },
      input: (css) => {
        return {
          ...css,
          input: {
            outline: 'none',
            outlineOffset: 0,
            boxShadow: 'none !important',
          },
        };
      },
      menu: (provided) => ({ ...provided, zIndex: 50 }),
      ...style,
    };

    const selectClass = classNames('select', `select-${selectSize}`, className);

    return (
      <Component
        className={selectClass}
        classNamePrefix={'select'}
        ref={ref}
        styles={styles}
        theme={(theme: Theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            neutral20: mode === 'dark' ? twColor.gray['600'] : twColor.gray['300'],
            neutral30: mode === 'dark' ? twColor.gray['600'] : twColor.gray['300'],
            neutral80: twColor.gray['700'],
            neutral10: mode === 'dark' ? twColor.gray['600'] : twColor.gray['300'],
            primary25: twColor[themeColor]['50'],
            primary50: twColor[themeColor]['100'],
            primary: twColor[themeColor][primaryColorLevel],
          },
        })}
        themeColor={`${themeColor}-${primaryColorLevel}`}
        components={{
          IndicatorSeparator: () => null,
          Option: DefaultOption,
          LoadingIndicator: DefaultLoadingIndicator,
          DropdownIndicator: DefaultDropdownIndicator,
          ClearIndicator: DefaultClearIndicator,
          ...(components as Partial<typeof components>),
        }}
        {...field}
        {...rest}
      />
    );
  },
);
