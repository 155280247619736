import React, { forwardRef, useCallback, useMemo, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { SegmentContextProvider } from './context';
import { useControllableState } from '../hooks';
import { useForm } from '../Form/context';
import { useInputGroup } from '../InputGroup/context';
import { useConfig } from '../ConfigProvider';
import { Size } from '../utils';

export type SegmentProps = {
  value: string | string[];
  defaultValue?: string | string[];
  onChange?: (val: any) => void;
  className?: string;
  selectionType?: 'single' | 'multiple';
  size?: Size;
};

export const Segment = forwardRef<HTMLDivElement, PropsWithChildren<SegmentProps>>(
  (
    {
      value: valueProp,
      defaultValue,
      onChange = () => {},
      children,
      className,
      selectionType = 'single',
      size,
      ...otherProps
    },
    ref,
  ) => {
    const formControl = useForm();
    const inputGroupControl = useInputGroup();
    const { controlSize } = useConfig();

    const [value, setValue] = useControllableState({
      prop: valueProp,
      defaultProp: defaultValue,
      onChange: onChange,
      //   selectionType,
    });

    const onActive = useCallback(
      (itemValue: string | string[]) => {
        setValue(itemValue);
      },
      [setValue],
    );

    const onDeactivate = useCallback(
      (itemValue: string | string[]) => {
        if (selectionType === 'single') {
          setValue('');
        }

        if (selectionType === 'multiple') {
          setValue((prevValue = []) => {
            //@ts-expect-error
            return prevValue.filter((value: string) => value !== itemValue);
          });
        }
      },
      [setValue, selectionType],
    );

    const segmentValue = useMemo(() => {
      if (selectionType === 'single') {
        if (value && typeof value === 'string') {
          return [value];
        }

        if (value && Array.isArray(value)) {
          return value;
        }

        return [];
      }

      if (selectionType === 'multiple') {
        return value ? value : [];
      }
      return value;
    }, [selectionType, value]);

    return (
      <SegmentContextProvider
        value={{
          //@ts-expect-error
          value: segmentValue,
          onActive: onActive,
          onDeactivate: onDeactivate,
          size: size || inputGroupControl?.size || formControl?.size || controlSize,
          selectionType,
        }}
      >
        <div ref={ref} className={classNames('segment', className)} {...otherProps}>
          {children}
        </div>
      </SegmentContextProvider>
    );
  },
);
