import { createContext, useContext } from 'react';
import { SIZES, Size } from '../utils/constant';

export type Config = {
  themeColor: string;
  direction: 'ltr' | 'rtl';
  mode: 'light' | 'dark';
  locale: string;
  primaryColorLevel: number;
  cardBordered: boolean;
  controlSize: Size;
  navMode: 'light' | 'dark' | 'themed';
};

export const defaultConfig: Config = {
  themeColor: 'indigo',
  direction: 'ltr',
  mode: 'light',
  locale: 'en',
  primaryColorLevel: 600,
  cardBordered: false,
  controlSize: SIZES.MD,
  navMode: 'light',
};

export const ConfigContext = createContext<Config>(defaultConfig);

const ConfigProvider = ConfigContext.Provider;

export const ConfigConsumer = ConfigContext.Consumer;

export function useConfig() {
  return useContext(ConfigContext);
}

export default ConfigProvider;
