import { FunctionComponent, useEffect } from 'react';
import { PrivateNavigator } from './PrivateNavigator';
import { PublicNavigator } from './PublicNavigator';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { authenticateFromLocalStorage } from 'src/store/authSlice';
import { isTokenExpired } from 'src/utils';

export const RootNavigator: FunctionComponent = () => {
  const { isLoading, tokens } = useAppSelector((state) => state.auth);
  const isSignedIn = tokens !== null && !isTokenExpired(tokens);
  const dispatch = useAppDispatch();

  useEffect(() => {
    async function runEffect() {
      await dispatch(authenticateFromLocalStorage()).unwrap();
    }
    runEffect();
  }, [dispatch]);

  if (isLoading) {
    return null;
  }

  return isSignedIn ? <PrivateNavigator /> : <PublicNavigator />;
};
