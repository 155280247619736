import { createContext, useContext } from 'react';

export type SegmentContextType = {
  size: string | null;
  value: string[] | null;
  onActive: ((val: string | string[]) => void) | null;
  onDeactivate: ((val: string | string[]) => void) | null;
  selectionType: 'single' | 'multiple' | null;
};

const SegmentContext = createContext<SegmentContextType>({
  size: null,
  value: null,
  onActive: null,
  onDeactivate: null,
  selectionType: null,
});

export const SegmentContextProvider = SegmentContext.Provider;

export const SegmentContextConsumer = SegmentContext.Consumer;

export function useSegment() {
  return useContext(SegmentContext);
}

export default SegmentContext;
