import { createContext, useState, useRef, useCallback, RefObject } from 'react';
import isNil from 'lodash/isNil';

export type DropdownMenuContextType = {
  registerItem: (ref: HTMLLIElement, options: { disabled?: boolean }) => void;
  unRegisterItem: (id: string) => void;
} | null;

type Item = { element: HTMLLIElement; props: any };

const DropdownMenuContext = createContext<DropdownMenuContextType>(null);

export const DropdownMenuContextProvider = DropdownMenuContext.Provider;

export const DropdownMenuContextConsumer = DropdownMenuContext.Consumer;

export function useDropdownMenuContext(menuRef: RefObject<HTMLUListElement>, _menuControl?: DropdownMenuContextType) {
  const [open, setOpen] = useState(false);

  const [items, setItems] = useState<Item[]>([]);
  const [activeItemIndex, setActiveItemIndex] = useState<number | null>(null);
  const previousActiveElementRef = useRef<Element | null>(null);

  const registerItem = useCallback((element: HTMLLIElement, props: any) => {
    setItems((items) => [...items, { element, props }]);
  }, []);

  const unRegisterItem = useCallback((id: string) => {
    setItems((items) => items.filter((item) => item.element.id !== id));
  }, []);

  const focusSelf = useCallback(() => {
    requestAnimationFrame(() => {
      if (document.activeElement !== menuRef.current) {
        previousActiveElementRef.current = document.activeElement;
        menuRef.current?.focus();
      }
    });
  }, [menuRef]);

  const focusItem = useCallback(
    (item: Item) => {
      const itemIndex = items.indexOf(item);
      if (itemIndex !== -1) {
        setActiveItemIndex(itemIndex);
        focusSelf();
      }
    },
    [items, focusSelf],
  );

  const lookupNextActiveItemIndex = useCallback(
    (start: number, direction: number) => {
      for (let i = start; i > -1 && i < items.length; i += direction) {
        if (!items[i].props?.disabled) {
          return i;
        }
      }
      return null;
    },
    [items],
  );

  const focusItemAt = useCallback(
    (index: number) => {
      if (isNil(index)) {
        setActiveItemIndex(null);
        focusSelf();
      } else {
        let activeItemIndex;
        if (index === 0) {
          activeItemIndex = lookupNextActiveItemIndex(0, 1);
        } else if (index === -1) {
          activeItemIndex = lookupNextActiveItemIndex(items.length - 1, -1);
        }

        if (!isNil(activeItemIndex)) {
          focusItem(items[activeItemIndex]);
        }
      }
    },
    [items, focusItem, focusSelf, lookupNextActiveItemIndex],
  );

  const openMenu = useCallback(() => {
    setOpen(true);
    focusSelf();
  }, [focusSelf]);

  const closeMenu = useCallback(() => {
    setOpen(false);
    setActiveItemIndex(null);
    requestAnimationFrame(() => {
      //@ts-expect-error
      previousActiveElementRef.current?.focus();
    });
  }, []);

  return {
    open,
    items,
    activeItemIndex,
    registerItem,
    unRegisterItem,
    focusItemAt,
    openMenu,
    closeMenu,
  };
}

export default DropdownMenuContext;
