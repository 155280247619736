import { MouseEvent, createContext } from 'react';
import { ThemeState } from 'src/store';

export type MenuContextType = {
  defaultActiveKeys: string[];
  defaultExpandedKeys?: string[];
  menuItemHeight: number;
  onSelect?: (key: string, event: MouseEvent) => void;
  sideCollapsed?: boolean;
  variant?: ThemeState['navMode'];
};

const MenuContext = createContext<MenuContextType>({ menuItemHeight: 0, defaultActiveKeys: [] });

export const MenuContextProvider = MenuContext.Provider;

export const MenuContextConsumer = MenuContext.Consumer;

export default MenuContext;
