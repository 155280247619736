import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/hooks';
import { setDirection } from 'src/store/themeSlice';

export const useDirection = () => {
  const direction = useAppSelector((state) => state.theme.direction);

  const dispatch = useDispatch();
  const updateDirection = (dir: HTMLElement['dir']) => dispatch(setDirection(dir));

  useEffect(() => {
    if (window === undefined) {
      return;
    }
    const root = window.document.documentElement;
    root.setAttribute('dir', direction);
  }, [direction]);

  return [direction, updateDirection] as const;
};
