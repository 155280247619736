import { forwardRef, ElementType, PropsWithChildren } from 'react';
import classNames from 'classnames';

export type TableProps = {
  borderlessRow?: boolean;
  className?: string;
  hoverable?: boolean;
  compact?: boolean;
  overflow?: boolean;
  asElement?: ElementType;
};

export const Table = forwardRef<HTMLDivElement, PropsWithChildren<TableProps>>(
  (
    {
      borderlessRow = false,
      children,
      className,
      hoverable = true,
      compact = false,
      overflow = true,
      asElement: Component = 'table',
      ...otherProps
    },
    ref,
  ) => {
    const tableClass = classNames(
      Component === 'table' ? 'table-default' : 'table-flex',
      hoverable && 'table-hover',
      compact && 'table-compact',
      borderlessRow && 'borderless-row',
      className,
    );

    return (
      <div className={classNames(overflow && 'overflow-x-auto')}>
        <Component className={tableClass} {...otherProps} ref={ref}>
          {children}
        </Component>
      </div>
    );
  },
);
