import * as Sentry from '@sentry/react';
import { IS_PROD } from 'src/config/env';

export const captureException: typeof Sentry.captureException = (...args): string => {
  if (!IS_PROD) {
    return '';
  }
  return Sentry.captureException(...args);
};

export const captureMessage: typeof Sentry.captureMessage = (...args): string => {
  if (!IS_PROD) {
    return '';
  }
  return Sentry.captureMessage(...args);
};

export const addBreadcrumb: typeof Sentry.addBreadcrumb = (...args): void => {
  if (!IS_PROD) {
    return;
  }
  return Sentry.addBreadcrumb(...args);
};

export const setUser: typeof Sentry.setUser = (...args): void => {
  if (!IS_PROD) {
    return;
  }
  return Sentry.setUser(...args);
};
