import classNames from 'classnames';
import isNil from 'lodash/isNil';
import {
  ElementType,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  Ref,
  StyleHTMLAttributes,
  cloneElement,
  forwardRef,
  isValidElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { ValueOf } from 'src/types';
import { useConfig } from '../ConfigProvider';
import { MenuItem } from '../MenuItem';
import { useUncertainRef, useUniqueId } from '../hooks';
import { DROPDOWN_ITEM_TYPE, chainedFunction } from '../utils';
import DropdownContext from './context/dropdownContext';
import DropdownMenuContext, {
  DropdownMenuContextProvider,
  useDropdownMenuContext,
} from './context/dropdownMenuContext';
import MenuContext from './context/menuContext';

const { DEFAULT, DIVIDER, HEADER, CUSTOM } = DROPDOWN_ITEM_TYPE;

export type DropdownItemProps = {
  asElement?: ElementType;
  active?: boolean;
  disabled?: boolean;
  className?: string;
  subMenu?: JSX.Element;
  style?: StyleHTMLAttributes<HTMLLIElement | HTMLDivElement>;
  eventKey: string;
  onClick?: () => void;
  onSelect?: (eventKey: string, event: MouseEvent) => void;
  variant?: ValueOf<typeof DROPDOWN_ITEM_TYPE>;
  icon?: ReactNode;
  trigger?: string;
};

export const DropdownItem = forwardRef<HTMLDivElement, PropsWithChildren<DropdownItemProps>>(
  (
    {
      asElement: Component = 'li',
      children,
      active: activeProp,
      disabled,
      className,
      subMenu,
      style,
      eventKey,
      onClick,
      onSelect,
      variant = DEFAULT,
      ...otherProps
    },
    ref,
  ) => {
    const { mode, direction } = useConfig();

    const menuitemRef = useUncertainRef<HTMLDivElement>(ref) as Ref<HTMLDivElement>;
    const menuitemId = useUniqueId('menu-item-');
    const submenuRef = useRef<HTMLUListElement | null>(null);

    const dropdown = useContext(DropdownContext);
    const menu = useContext(MenuContext);
    const menuControl = useContext(DropdownMenuContext);
    const submenuControl = useDropdownMenuContext(submenuRef);

    const open = submenuControl.open;

    const active =
      activeProp ||
      (!isNil(menu?.activeKey) && menu?.activeKey === eventKey) ||
      (!isNil(dropdown?.activeKey) && dropdown?.activeKey === eventKey);

    const openSubmenuIfExists = useCallback(() => {
      if (!subMenu) {
        return;
      }
      submenuControl.openMenu();
      submenuControl.focusItemAt(0);
    }, [subMenu, submenuControl]);

    const activate = useCallback(
      (e: MouseEvent) => {
        onSelect?.(eventKey, e);
        menu?.onSelect?.(eventKey, e);
      },
      [eventKey, onSelect, menu],
    );

    const handleClick = useCallback(
      (e: MouseEvent) => {
        if (disabled) {
          return;
        }

        if (subMenu) {
          openSubmenuIfExists();
        } else {
          activate(e);
        }
      },
      [disabled, subMenu, openSubmenuIfExists, activate],
    );

    const handleMouseOver = useCallback(() => {
      if (subMenu) {
        submenuControl.openMenu();
      }
    }, [subMenu, submenuControl]);

    const handleMouseOut = useCallback(() => {
      if (subMenu) {
        submenuControl.closeMenu();
      }
    }, [subMenu, submenuControl]);

    const menuitemEventHandlers: {
      onClick?: () => void;
      onMouseOver?: () => void;
      onMouseOut?: () => void;
    } = {
      //@ts-expect-error
      onClick: chainedFunction(handleClick, onClick),
    };

    const { registerItem, unRegisterItem } = menuControl ?? {};

    if (subMenu) {
      menuitemEventHandlers.onMouseOver = handleMouseOver;
      menuitemEventHandlers.onMouseOut = handleMouseOut;
    }

    useEffect(() => {
      if (variant !== DIVIDER && variant !== HEADER) {
        //@ts-expect-error
        registerItem?.(menuitemRef.current, { disabled });
      }
      return () => {
        unRegisterItem?.(menuitemId);
      };
    }, [registerItem, unRegisterItem, menuitemRef, menuitemId, disabled, variant]);

    if (variant === DIVIDER || variant === HEADER || variant === CUSTOM) {
      return (
        <Component
          ref={menuitemRef}
          id={menuitemId}
          style={style}
          className={classNames(`menu-item-${variant}`, className)}
          {...(variant === CUSTOM ? menuitemEventHandlers : {})}
          {...otherProps}
        >
          {(variant === HEADER || variant === CUSTOM) && children}
        </Component>
      );
    }

    function renderChildren() {
      if (!isValidElement(children)) {
        return children;
      }
      return cloneElement(children);
    }

    function renderSubmenu() {
      if (!subMenu) {
        return null;
      }

      return (
        <DropdownMenuContextProvider value={submenuControl}>
          {cloneElement(subMenu, {
            ref: submenuRef,
            hidden: !open,
          })}
        </DropdownMenuContextProvider>
      );
    }

    if (subMenu) {
      return (
        <li {...otherProps} style={style} className="relative" {...menuitemEventHandlers}>
          <MenuItem
            asElement="div"
            ref={menuitemRef}
            id={menuitemId}
            isActive={active}
            eventKey={eventKey}
            variant={mode}
            className={classNames('dropdown-submenu-item', className)}
          >
            <span>{children}</span>
            {direction === 'rtl' ? <HiChevronLeft /> : <HiChevronRight />}
          </MenuItem>
          {renderSubmenu()}
        </li>
      );
    }

    return (
      <MenuItem
        asElement="li"
        ref={menuitemRef}
        style={style}
        isActive={active}
        disabled={disabled}
        eventKey={eventKey}
        variant={mode}
        className={className}
        {...menuitemEventHandlers}
        {...otherProps}
      >
        {renderChildren()}
        {renderSubmenu()}
      </MenuItem>
    );
  },
);
