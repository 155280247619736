import { Ref, useRef } from 'react';

export const useUncertainRef = <T>(ref: Ref<T>) => {
  const newRef = useRef<T>();

  if (ref) {
    return ref;
  }

  return newRef;
};
