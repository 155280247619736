import { FunctionComponent } from 'react';

export type TotalProps = { total: number };

export const Total: FunctionComponent<TotalProps> = ({ total }) => {
  return (
    <div className="pagination-total">
      Total <span>{total}</span> Items
    </div>
  );
};
