import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import fr from './lang/fr.json';
import en from './lang/en.json';
import es from './lang/es.json';
import { LANGUAGE_COOKIE } from 'src/config/cookies';

const resources = {
  fr: {
    translation: fr,
  },
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
};

export type TranslationFilesFormat = typeof resources.en.translation;

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  lng: localStorage.getItem(LANGUAGE_COOKIE) || 'en',
  interpolation: {
    escapeValue: false,
  },
});

export const dateLocales = {
  fr: () => import('dayjs/locale/fr'),
  en: () => import('dayjs/locale/en'),
  es: () => import('dayjs/locale/es'),
};

export default i18n;
