import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useCallbackRef } from './useCallbackRef';

export type UseUncontrolledStateProps<T> = {
  defaultProp: T | (() => T);
  onChange: (value: T) => void;
};

export const useUncontrolledState = <T>({ defaultProp, onChange }: UseUncontrolledStateProps<T>) => {
  const uncontrolledState = useState(defaultProp);
  const [value] = uncontrolledState;
  const prevValueRef = useRef(value);
  const handleChange = useCallbackRef(onChange);

  useEffect(() => {
    if (prevValueRef.current !== value) {
      handleChange(value);
      prevValueRef.current = value;
    }
  }, [value, prevValueRef, handleChange]);

  return uncontrolledState;
};

export type UseControllableStateProps<T> = {
  prop: T;
  defaultProp: T | (() => T);
  onChange: (value: T) => void;
};

export const useControllableState = <T>({ prop, defaultProp, onChange = () => {} }: UseControllableStateProps<T>) => {
  const [uncontrolledProp, setUncontrolledProp] = useUncontrolledState({
    defaultProp,
    onChange,
  });
  const isControlled = prop !== undefined;
  const value = isControlled ? prop : uncontrolledProp;
  const handleChange = useCallbackRef(onChange);

  const setValue = useCallback(
    (nextValue: SetStateAction<T>) => {
      const setter = nextValue;
      if (isControlled) {
        //@ts-ignore
        const value = typeof nextValue === 'function' ? setter(prop) : nextValue;
        if (value !== prop) {
          handleChange(value);
        }
      } else {
        setUncontrolledProp(nextValue);
      }
    },
    [isControlled, prop, setUncontrolledProp, handleChange],
  );

  return [value, setValue] as const;
};
