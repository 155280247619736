import { createSlice } from '@reduxjs/toolkit';
import { RouteCustomObject } from 'src/config/navigation/routes';

export interface CommonState {
  currentRouteKey: RouteCustomObject['key'];
}

export const initialState: CommonState = {
  currentRouteKey: 'pages',
};

export const commonSlice = createSlice({
  name: 'base/common',
  initialState,
  reducers: {
    setCurrentRouteKey: (state, action) => {
      state.currentRouteKey = action.payload;
    },
  },
});

export const { setCurrentRouteKey } = commonSlice.actions;

export const commonSliceReducer = commonSlice.reducer;
