import classNames from 'classnames';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useConfig } from '../ConfigProvider';
import { Next } from './Next';
import { Pager } from './Pager';
import { Prev } from './Prev';
import { Total } from './Total';

export type PagerClass = {
  default: string;
  inactive: string;
  active: string;
  disabled: string;
};

export type PaginationProps = {
  className?: string;
  currentPage: number;
  displayTotal?: boolean;
  onChange: ((arg: string | number) => () => number) | ((arg: string | number) => void);
  pageSize: number;
  total: number;
};

export const Pagination: FunctionComponent<PaginationProps> = ({
  className,
  currentPage = 1,
  displayTotal = false,
  onChange,
  pageSize = 1,
  total = 5,
}) => {
  const [paginationTotal, setPaginationTotal] = useState(total);
  const [internalPageSize, setInternalPageSize] = useState(pageSize);

  const { themeColor, primaryColorLevel } = useConfig();

  const getInternalPageCount = useMemo(() => {
    if (typeof paginationTotal === 'number') {
      return Math.ceil(paginationTotal / internalPageSize);
    }
    return -1;
  }, [paginationTotal, internalPageSize]);

  const getValidCurrentPage = useCallback(
    (count: string | number) => {
      const value = typeof count === 'string' ? parseInt(count, 10) : count;
      const internalPageCount = getInternalPageCount;
      let resetValue;
      if (!internalPageCount) {
        if (isNaN(value) || value < 1) {
          resetValue = 1;
        }
      } else {
        if (value < 1) {
          resetValue = 1;
        }
        if (value > internalPageCount) {
          resetValue = internalPageCount;
        }
      }

      if ((resetValue === undefined && isNaN(value)) || resetValue === 0) {
        resetValue = 1;
      }

      return resetValue === undefined ? value : resetValue;
    },
    [getInternalPageCount],
  );

  const [internalCurrentPage, setInternalCurrentPage] = useState(currentPage ? getValidCurrentPage(currentPage) : 1);

  useEffect(() => {
    if (total !== paginationTotal) {
      setPaginationTotal(total);
    }

    if (pageSize !== internalPageSize) {
      setInternalPageSize(pageSize);
    }

    if (currentPage !== internalCurrentPage) {
      setInternalCurrentPage(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, pageSize, currentPage]);

  const onPaginationChange = (val: number) => {
    setInternalCurrentPage(getValidCurrentPage(val));
    onChange?.(getValidCurrentPage(val));
  };

  const onPrev = useCallback(() => {
    const newPage = internalCurrentPage - 1;
    setInternalCurrentPage(getValidCurrentPage(newPage));
    onChange?.(getValidCurrentPage(newPage));
  }, [onChange, internalCurrentPage, getValidCurrentPage]);

  const onNext = useCallback(() => {
    const newPage = internalCurrentPage + 1;
    setInternalCurrentPage(getValidCurrentPage(newPage));
    onChange?.(getValidCurrentPage(newPage));
  }, [onChange, internalCurrentPage, getValidCurrentPage]);

  const pagerClass: PagerClass = {
    default: 'pagination-pager',
    inactive: 'pagination-pager-inactive',
    active: `text-${themeColor}-${primaryColorLevel} bg-${themeColor}-50 hover:bg-${themeColor}-50 dark:bg-${themeColor}-${primaryColorLevel} dark:text-gray-100`,
    disabled: 'pagination-pager-disabled',
  };

  const paginationClass = classNames('pagination', className);

  return (
    <div className={paginationClass}>
      {displayTotal && <Total total={total} />}
      <Prev currentPage={internalCurrentPage} pagerClass={pagerClass} onPrev={onPrev} />
      <Pager
        onChange={onPaginationChange}
        pageCount={getInternalPageCount}
        currentPage={internalCurrentPage}
        pagerClass={pagerClass}
      />
      <Next
        currentPage={internalCurrentPage}
        pageCount={getInternalPageCount}
        pagerClass={pagerClass}
        onNext={onNext}
      />
    </div>
  );
};
