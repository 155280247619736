import { createContext, useContext } from 'react';

const TabsContext = createContext<{
  value: string | undefined;
  variant: string | null;
  onValueChange: ((val: string) => void) | null;
}>({ value: undefined, variant: null, onValueChange: null });

export const TabsContextProvider = TabsContext.Provider;

export const TabsContextConsumer = TabsContext.Consumer;

export function useTabs() {
  return useContext(TabsContext);
}

export default TabsContext;
