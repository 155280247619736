import classNames from 'classnames';
import { ReactNode, forwardRef } from 'react';
import { useTabs } from './context';

export type TabContentProps = {
  value: string;
  children: ReactNode;
  className?: string;
};

export const TabContent = forwardRef<HTMLDivElement, TabContentProps>(
  ({ value, children, className, ...rest }, ref) => {
    const context = useTabs();
    const isSelected = value === context.value;

    const tabContentClass = classNames('tab-content', isSelected && 'tab-content-active', className);

    return (
      <div role="tabpanel" tabIndex={0} className={tabContentClass} ref={ref} {...rest}>
        {isSelected && children}
      </div>
    );
  },
);
