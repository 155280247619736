import { MouseEvent, FunctionComponent } from 'react';
import classNames from 'classnames';
import { HiChevronLeft } from 'react-icons/hi';
import { PagerClass } from './Pagination';

export type PrevProps = { currentPage: number; pagerClass: PagerClass; onPrev: (e: MouseEvent) => void };

export const Prev: FunctionComponent<PrevProps> = (props) => {
  const { currentPage, pagerClass, onPrev } = props;

  const disabled = currentPage <= 1;

  const onPrevClick = (e: MouseEvent) => {
    if (disabled) {
      return;
    }
    onPrev(e);
  };

  const pagerPrevClass = classNames(
    pagerClass.default,
    'pagination-pager-prev',
    disabled ? pagerClass.disabled : pagerClass.inactive,
  );

  return (
    <span className={pagerPrevClass} onClick={onPrevClick}>
      <HiChevronLeft />
    </span>
  );
};
