import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren, ReactElement, Suspense } from 'react';
import { PAGE_CONTAINER_GUTTER_X, PAGE_CONTAINER_GUTTER_Y } from 'src/config/theme';
import { Container } from './Container';
import { Footer } from './Footer';
import { useTranslation } from 'react-i18next';

const CustomHeader = ({ header }: { header?: ReactElement }) => {
  const Header = header;
  //@ts-expect-error
  return <Header />;
};

export type PageContainerProps = {
  pageContainerType?: 'default' | 'gutterless' | 'contained';
  header?: string | ReactElement;
  contained?: boolean;
  extraHeader?: string | ReactElement;
  footer?: boolean;
};

export const PageContainer: FunctionComponent<PropsWithChildren<PageContainerProps>> = ({
  pageContainerType = 'default',
  children,
  header,
  contained = false,
  extraHeader,
  footer = true,
}) => {
  const { t } = useTranslation();
  const translatedHeader = header && typeof header === 'string' && t(`nav.pages.${header.toLowerCase()}`);

  return (
    <div className="flex h-full flex-auto flex-col justify-between">
      <main className="h-full">
        <div
          className={classNames(
            'page-container relative flex h-full flex-auto flex-col',
            pageContainerType !== 'gutterless' && `${PAGE_CONTAINER_GUTTER_X} ${PAGE_CONTAINER_GUTTER_Y}`,
            pageContainerType === 'contained' && 'container mx-auto',
          )}
        >
          {(header || extraHeader) && (
            <div className={classNames(contained && 'container mx-auto', 'mb-4 flex items-center justify-between')}>
              <div>
                {translatedHeader && <h3>{translatedHeader}</h3>}
                <Suspense fallback={<div></div>}>
                  {header && typeof header !== 'string' && <CustomHeader header={header} />}
                </Suspense>
              </div>
              <Suspense fallback={<div></div>}>
                {extraHeader && typeof extraHeader !== 'string' && <CustomHeader header={extraHeader} />}
              </Suspense>
            </div>
          )}
          {pageContainerType === 'contained' ? (
            <Container className="h-full">
              <>{children}</>
            </Container>
          ) : (
            <>{children}</>
          )}
        </div>
      </main>
      {footer && <Footer pageContainerType={pageContainerType} />}
    </div>
  );
};
