import { FunctionComponent } from 'react';
import { LogInForm } from './LogInForm';
import { useTranslation } from 'react-i18next';

export type LogInProps = Record<string, never>;

export const LogIn: FunctionComponent<LogInProps> = ({ ...otherProps }) => {
  const { t } = useTranslation();
  return (
    <div {...otherProps}>
      <div className="mb-4 py-4">
        <h3 className="mb-1">{t('auth.login.title')}</h3>
        <p>{t('auth.login.subtitle')}</p>
      </div>
      <LogInForm disableSubmit={false} />
    </div>
  );
};
