import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query';
import { QueryCacheLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Client, Content, Product, Usage, WorkOrder } from 'src/types';

import {
  setupGetClientsCacheLogic,
  setupGetContentsCacheLogic,
  setupGetProductUsageCacheLogic,
  setupGetWorkOrdersCacheLogic,
  setupSearchProductsCacheLogic,
} from './cachePlugins';
import {
  GetClientsQueryParams,
  GetProductUsageQueryParams,
  GetStockContentsQueryParams,
  GetWorkOrdersQueryParams,
  SearchProductsPayload,
} from '../types';

type CachedTypes = Client[] | Content[] | WorkOrder[] | Usage | Product[];

const getSafeTopic = (security: string, baseTopic: string) => {
  let prefix = '';
  switch (security) {
    case 'customer':
      prefix = '/api/customers/{id}';
      break;
  }
  return prefix + baseTopic;
};

export type RTKQueryCacheLifecycleApi<Arg> = QueryCacheLifecycleApi<
  Arg,
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
  CachedTypes,
  'mobystockApi'
>;

export type OnCacheEntryAdded<T> = (
  arg: T,
  api: QueryCacheLifecycleApi<
    T,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
    CachedTypes,
    'mobystockApi'
  >,
) => void | Promise<void>;

export const onGetClientsCacheEntryAdded: OnCacheEntryAdded<GetClientsQueryParams> = async (arg, api) => {
  await setupGetClientsCacheLogic<GetClientsQueryParams>(api, arg, [getSafeTopic('customer', '/api/clients/{id}')]);
};

export const onGetStockContentsCacheEntryAdded: OnCacheEntryAdded<GetStockContentsQueryParams> = async (arg, api) => {
  await setupGetContentsCacheLogic<GetStockContentsQueryParams>(api, arg, [
    '/api/contents/{id}',
    '/api/restocks/{id}',
    '/api/receptions/{id}',
    '/api/destocks/{id}',
  ]);
};

export const onGetWorkOrdersCacheEntryAdded: OnCacheEntryAdded<GetWorkOrdersQueryParams> = async (arg, api) => {
  await setupGetWorkOrdersCacheLogic<GetWorkOrdersQueryParams>(api, arg, [
    getSafeTopic('customer', '/api/work_orders/{id}'),
  ]);
};

export const onGetProductUsageCacheEntryAdded: OnCacheEntryAdded<GetProductUsageQueryParams> = async (arg, api) => {
  await setupGetProductUsageCacheLogic<GetProductUsageQueryParams>(api, arg, [
    getSafeTopic('customer', '/api/usages/{id}'),
  ]);
};

export const onSearchProductsCacheEntryAdded: OnCacheEntryAdded<SearchProductsPayload> = async (arg, api) => {
  await setupSearchProductsCacheLogic<SearchProductsPayload>(api, arg, [getSafeTopic('customer', '/api/usages/{id}')]);
};
