import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { ReactElement, forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { ValueOf } from 'src/types';
import { chainedFunction } from '../utils';
import { PLACEMENT, Placement } from '../utils/constant';
import { getPlacementTransition } from './transition';

export type Message = { key: string; visible: boolean; node: ReactElement };

const useMessages = (msgKey?: string) => {
  const [messages, setMessages] = useState<Message[]>([]);

  const getKey = useCallback(
    (key: string) => {
      if (typeof key === 'undefined' && messages.length) {
        key = messages[messages.length - 1].key;
      }
      return key;
    },
    [messages],
  );

  const push = useCallback(
    (message: ReactElement) => {
      const key = msgKey || '_' + Math.random().toString(36).substr(2, 12);
      setMessages([...messages, { key, visible: true, node: message }]);
      return key;
    },
    [messages, msgKey],
  );

  const removeAll = useCallback(() => {
    setMessages(messages.map((msg) => ({ ...msg, visible: false })));
    setTimeout(() => {
      setMessages([]);
    }, 50);
  }, [messages]);

  const remove = useCallback(
    (key: string) => {
      setMessages(
        messages.map((elm) => {
          if (elm.key === getKey(key)) {
            elm.visible = false;
          }
          return elm;
        }),
      );

      setTimeout(() => {
        setMessages(messages.filter((msg) => msg.visible));
      }, 50);
    },
    [messages, getKey],
  );

  return { messages, push, removeAll, remove };
};

export type ToastPlacement = Omit<
  Placement,
  'MIDDLE_START_BOTTOM' | 'MIDDLE_START_TOP' | 'MIDDLE_END_TOP' | 'MIDDLE_END_BOTTOM'
>;

export type ToastWrapperProps = {
  transitionType?: 'scale' | 'fade';
  placement?: ValueOf<ToastPlacement>;
  offsetX?: number;
  offsetY?: number;
  messageKey?: string;
  block?: boolean;
  callback?: (ref: HTMLDivElement | null) => void;
};

export const toastDefaultProps = {
  placement: PLACEMENT.TOP_END,
  offsetX: 30,
  offsetY: 30,
  transitionType: 'scale',
  block: false,
} as Required<ToastWrapperProps>;

const ToastWrapper = forwardRef<unknown, ToastWrapperProps>(
  (
    {
      transitionType = toastDefaultProps.transitionType,
      placement = toastDefaultProps.placement,
      offsetX = toastDefaultProps.offsetX,
      offsetY = toastDefaultProps.offsetY,
      block = toastDefaultProps.block,
      messageKey,
      callback,
      ...otherProps
    },
    ref,
  ) => {
    const rootRef = useRef<HTMLDivElement | null>();

    const { push, removeAll, remove, messages } = useMessages(messageKey);

    useImperativeHandle(ref, () => {
      return { root: rootRef.current, push, removeAll, remove };
    });

    const placementTransition = getPlacementTransition({
      offsetX,
      offsetY,
      placement,
      transitionType,
    });

    const toastProps = {
      triggerByToast: true,
      ...otherProps,
    };

    const messageElements = messages.map((item) => {
      return (
        <motion.div
          key={item.key}
          className={'toast-wrapper'}
          initial={placementTransition?.variants.initial}
          variants={placementTransition?.variants}
          animate={item.visible ? 'animate' : 'exit'}
          transition={{ duration: 0.15, type: 'tween' }}
        >
          {React.cloneElement(item.node, {
            ...toastProps,
            ref,
            onClose: chainedFunction(item.node?.props?.onClose, () => remove(item.key)),
            className: classNames(item.node?.props?.className),
          })}
        </motion.div>
      );
    });

    return (
      <div
        style={placementTransition?.default}
        {...otherProps}
        ref={(thisRef) => {
          rootRef.current = thisRef;
          callback?.(thisRef);
        }}
        className={classNames('toast', block && 'w-full')}
      >
        {messageElements}
      </div>
    );
  },
);
//@ts-expect-error
ToastWrapper.getInstance = ({ wrapper, ...otherProps }: { wrapper: () => void | HTMLElement }) => {
  const wrapperRef = React.createRef();

  const wrapperElement = (typeof wrapper === 'function' ? wrapper() : wrapper) || document.body;

  return new Promise((resolve) => {
    const renderCallback = () => {
      resolve([wrapperRef, unmount]);
    };

    function renderElement(element: ReactElement) {
      const mountElement = document.createElement('div');

      wrapperElement.appendChild(mountElement);

      const root = createRoot(mountElement);

      root.render(element);
      //@ts-expect-error
      wrapperElement.__root = root;

      return root;
    }
    const { unmount } = renderElement(<ToastWrapper {...otherProps} ref={wrapperRef} callback={renderCallback} />);
  });
};

export default ToastWrapper;
