export const getIDFromStringID = (stringID: string) => {
  return stringID.substring(stringID.lastIndexOf('/') + 1);
};

/**
 * There are different ways to address objects :
 *            Iri     Id
 * internal    1      2
 * external    3      4
 *
 * 1 : backend in API routes
 * 2 : backend in searches
 * 3 : external in API routes
 * 4 : external in params
 *
 * These services are designed to provide Iri or Id whatever internal or external
 *
 */

/**
 * Get the object ID for external, and Iri for internal
 * @param object
 * @param useExternalFSM
 * @returns
 */
export const getIdOrIriFromObject = (object: any, useExternalFSM: boolean) => {
  if (useExternalFSM) {
    return getIDFromStringID(getIriFromObject(object, useExternalFSM));
  }
  return getIriFromObject(object, useExternalFSM);
};

/**
 * Get the object ID from whatever origin, internal or external
 * @param object
 * @param useExternalFSM
 * @returns
 */
export const getIdFromObject = (object: any, useExternalFSM: boolean) => {
  return getIDFromStringID(getIriFromObject(object, useExternalFSM));
};

/**
 * Build the proper Iri for the backend to treat internal and external objects
 * Here the Iri is either kept intact from the object, or rebuilt using the external Id
 *
 * @param object
 * @param useExternalFSM boolean
 * @returns
 */
export const getIriFromObject = (object: any, useExternalFSM: boolean) => {
  try {
    const iri = object?.['@id'];
    if (!useExternalFSM) return iri;
  } catch {
    console.log('error converting object Iri from backend');
    return '';
  }

  try {
    const iri = object?.['@id'];
    const iri_array = iri.split('/');
    // const iri_id = iri_array.pop();
    const externalId = object?.ExternalFsmId;
    const iri_ext = iri_array.join('/') + '/' + externalId;

    return useExternalFSM ? iri_ext : iri;
  } catch {
    console.log('error converting object Iri from external FSM');
    return '';
  }
};
