import { ElementType, PropsWithChildren, forwardRef } from 'react';
import classNames from 'classnames';

export type ContainerProps = {
  className?: string;
  asElement?: ElementType;
};

export const Container = forwardRef<HTMLElement, PropsWithChildren<ContainerProps>>(
  ({ className, children, asElement: Component = 'div', ...rest }, ref) => {
    return (
      <Component ref={ref} className={classNames('container mx-auto', className)} {...rest}>
        {children}
      </Component>
    );
  },
);
