import classNames from 'classnames';
import {
  ChangeEvent,
  PropsWithChildren,
  Ref,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { FieldInputProps, FormikProps } from 'formik';
import { omit } from 'lodash';
import { useConfig } from '../ConfigProvider';
import RadioGroupContext from './context';

export type RadioProps = {
  className?: string;
  checked?: boolean;
  color?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  field?: FieldInputProps<any>;
  form?: FormikProps<unknown>;
  id?: string;
  labelRef?: Ref<HTMLLabelElement>;
  name?: string;
  onChange?: (value: unknown, e: ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  value: string | null;
  vertical?: boolean;
};

export const Radio = forwardRef<HTMLInputElement, PropsWithChildren<RadioProps>>(
  (
    {
      children,
      className,
      checked: checkedProp,
      color,
      defaultChecked,
      field,
      form,
      id,
      labelRef,
      onChange,
      readOnly,
      value,
      ...otherProps
    },
    ref,
  ) => {
    const {
      name: nameContext,
      disabled: disabledContext,
      value: groupValue,
      onChange: onGroupChange,
      color: colorContext,
      vertical: verticalContext,
      radioGutter,
    } = useContext(RadioGroupContext);

    const { disabled = disabledContext, name = nameContext, vertical = verticalContext } = otherProps;

    const { themeColor, primaryColorLevel } = useConfig();

    const getChecked = () => {
      return typeof groupValue !== 'undefined' ? groupValue === value : checkedProp;
    };

    const [radioChecked, setRadioChecked] = useState(getChecked());

    const radioColor = color || colorContext || `${themeColor}-${primaryColorLevel}`;

    const controlProps = useMemo(() => {
      if (typeof groupValue !== 'undefined') {
        return { checked: radioChecked };
      }
      return { checked: checkedProp, defaultChecked };
    }, [radioChecked, checkedProp, defaultChecked, groupValue]);

    const onRadioChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (disabled || readOnly) {
          return;
        }
        onGroupChange?.(value, e);
        onChange?.(value, e);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [disabled, setRadioChecked, onChange, value, onGroupChange, groupValue, readOnly],
    );

    useEffect(() => {
      const propChecked = getChecked();
      if (radioChecked !== propChecked) {
        setRadioChecked(propChecked);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, checkedProp, groupValue]);

    const radioDefaultClass = `radio text-${radioColor}`;
    const radioColorClass = disabled && 'disabled';
    const labelDisabledClass = disabled && 'disabled';

    const radioClass = classNames(radioDefaultClass, radioColorClass);
    const labelClass = classNames(
      'radio-label',
      labelDisabledClass,
      className,
      `${'inline-flex'}`,
      `${radioGutter ? 'm' + (vertical ? 'b-' : 'r-') + radioGutter : ''}`,
    );

    return (
      <label ref={labelRef} className={labelClass}>
        <input
          id={id}
          ref={ref}
          type="radio"
          className={radioClass}
          disabled={disabled}
          //   @ts-expect-error
          value={value}
          onChange={onRadioChange}
          name={name}
          readOnly={readOnly}
          {...controlProps}
          {...omit(field, ['value', 'onChange', 'name'])}
          {...omit(otherProps, 'disabled', 'name')}
        />
        {children ? (
          <span className={classNames('ltr:ml-2 rtl:mr-2', disabled ? 'opacity-50' : '')}>{children}</span>
        ) : null}
      </label>
    );
  },
);
