import {
  Cell,
  ColumnDef,
  RowData,
  SortingState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { ForwardedRef, MouseEvent, Ref, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useContextMenu } from 'react-contexify';
import { Alert, Pagination, PagingData, Select, Table } from 'src/components/ui';
import { IS_DEV } from 'src/config/env';
import { SectionTableDataProps, useSectionTableData } from 'src/hooks';
import { ColumnDefI18n, SectionTableData } from 'src/types';
import { ContextMenu, ContextMenuConfig } from '../ui/ContextMenu';
import { IndeterminateCheckbox } from './DataTable';
import { Loading } from './Loading';
import { TableRowSkeleton, TableRowSkeletonProps } from './loaders';

const { Tr, Th, Td, THead, TBody } = Table;

export type SectionTableProps<T, U> = SectionTableDataProps & {
  columns: ColumnDefI18n<U>[];
  skeletonAvatarColumns: number[];
  loading: boolean;
  onCheckBoxChange?: (checked: boolean, row: unknown) => void;
  onIndeterminateCheckBoxChange?: (checked: boolean, rows: unknown) => void;
  onPaginationChange: (pageIndex: number) => void;
  onSelectChange: (pageSize: number) => void;
  onSort: (sort: PagingData['sort']) => void;
  pageSizes?: number[];
  selectable?: boolean;
  skeletonAvatarProps?: TableRowSkeletonProps['avatarProps'];
  pagingData: PagingData;
  contextMenuConfig?: ContextMenuConfig<T>;
};

const SectionTableInner = <T, U>(
  {
    skeletonAvatarColumns,
    columns: columnsProp = [],
    data = [],
    dataType,
    loading = false,
    onCheckBoxChange,
    onIndeterminateCheckBoxChange,
    onPaginationChange,
    onSelectChange,
    onSort,
    pageSizes = [10, 25, 50, 100],
    selectable = false,
    skeletonAvatarProps,
    pagingData = {
      total: 0,
      pageIndex: 1,
      pageSize: 25,
      sort: { key: '', order: 'asc' },
    },
    contextMenuConfig,
  }: SectionTableProps<T, U>,
  ref: Ref<unknown>,
) => {
  const { pageIndex, pageSize: initialPageSize, total } = pagingData;

  const { sections: groupedData } = useSectionTableData({ dataType, data } as SectionTableDataProps);
  const [pageSize, setPageSize] = useState(initialPageSize);

  const { show } = useContextMenu({
    id: contextMenuConfig?.menuId || 'menu-id',
  });

  const [sorting, setSorting] = useState<SortingState>([]);
  const [expandedCellText, setExpandedCellText] = useState<RowData | null>(null);

  const pageSizeOption = useMemo(
    () =>
      pageSizes?.map((number) => ({
        value: number,
        label: `${number} / page`,
      })),
    [pageSizes],
  );

  const handleCheckBoxChange = (checked: boolean, row: unknown) => {
    if (!loading) {
      onCheckBoxChange?.(checked, row);
    }
  };

  const handleIndeterminateCheckBoxChange = (checked: boolean, rows: unknown) => {
    if (!loading) {
      onIndeterminateCheckBoxChange?.(checked, rows);
    }
  };

  const handlePaginationChange = (page: number | string) => {
    const cleanPage = typeof page === 'number' ? page : Number(page);
    if (!loading) {
      onPaginationChange?.(cleanPage);
    }
  };

  const handleSelectChange = (value: string) => {
    if (!loading) {
      setPageSize(Number(value));
      onSelectChange?.(Number(value));
    }
  };

  useEffect(() => {
    if (Array.isArray(sorting)) {
      const sortOrder = sorting.length > 0 ? (sorting[0].desc ? 'desc' : 'asc') : 'desc';
      const id = sorting.length > 0 ? sorting[0].id : '';
      onSort?.({ order: sortOrder, key: id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  // @ts-expect-error
  const hasOldColumnMetaKey = columnsProp.some((col) => col.Header || col.accessor || col.Cell);

  const finalColumns = useMemo(() => {
    const columns = columnsProp;

    if (selectable) {
      const selectableColumns: ColumnDef<U>[] = [
        {
          id: 'select',
          header: ({ table }) => (
            <IndeterminateCheckbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
              onIndeterminateCheckBoxChange={(e) => {
                handleIndeterminateCheckBoxChange(e.target.checked, table.getRowModel().rows);
              }}
            />
          ),
          cell: ({ row }) => (
            <IndeterminateCheckbox
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
              onCheckBoxChange={(e) => handleCheckBoxChange(e.target.checked, row.original)}
            />
          ),
        },
        ...columns,
      ];
      return selectableColumns;
    }
    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsProp, selectable]);

  const table = useReactTable({
    data: groupedData,
    //@ts-expect-error
    columns: hasOldColumnMetaKey ? [] : finalColumns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    //@ts-expect-error
    getSubRows: (row) => row.subRows,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    state: {
      sorting,
    },
    autoResetExpanded: false,
  });

  useEffect(() => {
    table.toggleAllRowsExpanded(true);
  }, [table]);

  const resetSorting = () => {
    table.resetSorting();
  };

  const resetSelected = () => {
    table.toggleAllRowsSelected(false);
  };

  useImperativeHandle(ref, () => ({
    resetSorting,
    resetSelected,
  }));

  if (hasOldColumnMetaKey) {
    const message =
      'You are using old react-table v7 column config, please use v8 column config instead, refer to our demo or https://tanstack.com/table/v8';

    if (IS_DEV) {
      console.warn(message);
    }

    return <Alert>{message}</Alert>;
  }

  const handleCellClick = (row: RowData, cell: Cell<SectionTableData, unknown>) => {
    if (cell.id.includes('action')) return;
    if (expandedCellText === row) {
      setExpandedCellText(null);
    } else {
      setExpandedCellText(row);
    }
  };

  return (
    <Loading loading={loading && data.length !== 0} type="cover">
      <Table compact>
        <THead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                    )}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </THead>
        {loading && data.length === 0 ? (
          <TableRowSkeleton
            columns={finalColumns.length}
            rows={pagingData.pageSize}
            avatarInColumns={skeletonAvatarColumns}
            avatarProps={skeletonAvatarProps}
          />
        ) : (
          <TBody>
            {table
              .getRowModel()
              .rows.slice(0, pageSize)
              .map((row) => {
                const isTextExpanded = row.original === expandedCellText;
                // Parent row
                return !row.id.includes('.') ? (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>;
                    })}
                  </Tr>
                ) : (
                  // Child row / sub row
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      function displayMenu(e: MouseEvent<HTMLTableCellElement>) {
                        show({
                          event: e,
                          props: row.original,
                        });
                      }
                      return (
                        <Td
                          //@ts-expect-error
                          onClick={() => handleCellClick(row.original, cell)}
                          key={cell.id}
                          className={classNames(
                            'max-w-[100px] cursor-pointer',
                            !isTextExpanded && 'overflow-hidden overflow-ellipsis whitespace-nowrap',
                          )}
                          onContextMenu={displayMenu}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </TBody>
        )}
      </Table>
      <div className="mt-4 flex items-center justify-between">
        <Pagination pageSize={pageSize} currentPage={pageIndex} total={total} onChange={handlePaginationChange} />
        <div style={{ minWidth: 130 }}>
          <Select
            size="sm"
            menuPlacement="top"
            isSearchable={false}
            value={pageSizeOption.filter((option) => option.value === pageSize)}
            options={pageSizeOption}
            onChange={(option) => handleSelectChange(option.value)}
          />
        </div>
      </div>
      {contextMenuConfig && <ContextMenu contextMenuConfig={contextMenuConfig} />}
    </Loading>
  );
};

export const SectionTable = forwardRef(SectionTableInner) as <T, U>(
  props: SectionTableProps<T, U> & { ref?: ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof SectionTableInner>;
