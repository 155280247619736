import { FunctionComponent, ReactNode } from 'react';
import { HiCheckCircle, HiExclamation, HiInformationCircle, HiXCircle } from 'react-icons/hi';

const ICONS = {
  success: {
    color: 'text-emerald-400',
    icon: <HiCheckCircle />,
  },
  info: {
    color: 'text-blue-400',
    icon: <HiInformationCircle />,
  },
  warning: {
    color: 'text-yellow-400',
    icon: <HiExclamation />,
  },
  danger: {
    color: 'text-red-400',
    icon: <HiXCircle />,
  },
};

export type StatusIconProps = {
  type?: 'info' | 'success' | 'warning' | 'danger';
  custom?: ReactNode;
  iconColor?: string;
};

export const StatusIcon: FunctionComponent<StatusIconProps> = ({ type = 'info', custom, iconColor }) => {
  const icon = ICONS[type];

  return <span className={`text-2xl ${iconColor || icon.color}`}>{custom || icon.icon}</span>;
};
