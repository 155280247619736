import classNames from 'classnames';
import { FunctionComponent, StyleHTMLAttributes } from 'react';
import { APP_NAME } from 'src/config/env';

const LOGO_SRC_PATH = '/logo.png';

export type LogoProps = {
  type?: 'full' | 'streamline';
  mode?: 'default' | 'light' | 'dark';
  gutter?: string;
  className?: string;
  imgClass?: string;
  style?: StyleHTMLAttributes<HTMLDivElement>;
  logoWidth?: string | number;
};

export const Logo: FunctionComponent<LogoProps> = ({
  //   type = 'full',
  //   mode = 'light',
  gutter,
  className,
  imgClass,
  style,
  logoWidth = 'auto',
}) => {
  return (
    <div
      className={classNames('logo', className, gutter)}
      style={{
        ...style,
        ...{ width: logoWidth },
      }}
    >
      <img
        className={imgClass}
        style={{
          ...style,
          ...{ borderRadius: 12 },
        }}
        src={`${LOGO_SRC_PATH}`}
        alt={`${APP_NAME} logo`}
      />
    </div>
  );
};
