import classNames from 'classnames';
import { ReactNode, forwardRef } from 'react';
import { useConfig } from '../ConfigProvider';
import { useForm } from '../Form/context';
import { CONTROL_SIZES, Size } from '../utils/constant';
import { useInputGroup } from './context';

export type AddonProps = {
  size: Size;
  children: ReactNode;
  className: string;
};

export const Addon = forwardRef<HTMLDivElement, AddonProps>((props, ref) => {
  const { size, children, className } = props;

  const { controlSize } = useConfig();
  const formControlSize = useForm()?.size;
  const inputGroupSize = useInputGroup()?.size;

  const inputAddonSize = size || inputGroupSize || formControlSize || controlSize;

  const addonClass = classNames(
    'input-addon',
    `input-addon-${inputAddonSize} h-${CONTROL_SIZES[inputAddonSize]}`,
    className,
  );

  return (
    <div ref={ref} className={addonClass}>
      {children}
    </div>
  );
});
