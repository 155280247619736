import { FunctionComponent, PropsWithChildren } from 'react';
import { MenuItem as Item } from '../MenuItem';
import { CollapseContextConsumer } from './context/collapseContext';
import { GroupContextConsumer } from './context/groupContext';
import { MenuContextConsumer } from './context/menuContext';

type MenuItemProps = {
  disabled?: boolean;
  eventKey: string;
  className?: string;
};

export const MenuItem: FunctionComponent<PropsWithChildren<MenuItemProps>> = ({
  eventKey,
  children,
  ...otherProps
}) => {
  return (
    <MenuContextConsumer>
      {(context) => (
        <GroupContextConsumer>
          {() => (
            <CollapseContextConsumer>
              {() => (
                <Item
                  onSelect={context.onSelect}
                  menuItemHeight={context.menuItemHeight}
                  variant={context.variant}
                  isActive={context.defaultActiveKeys.includes(eventKey)}
                  eventKey={eventKey}
                  {...otherProps}
                >
                  {children}
                </Item>
              )}
            </CollapseContextConsumer>
          )}
        </GroupContextConsumer>
      )}
    </MenuContextConsumer>
  );
};
