import { FunctionComponent, PropsWithChildren, Suspense, lazy, useMemo } from 'react';
import { Loading } from 'src/components';
import {
  LAYOUT_TYPE_BLANK,
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_DECKED,
  LAYOUT_TYPE_MODERN,
  LAYOUT_TYPE_SIMPLE,
  LAYOUT_TYPE_STACKED_SIDE,
} from 'src/config/theme';
import { useAppSelector, useDirection, useLocale } from 'src/hooks';

const layouts = {
  [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./components/ClassicLayout')),
  [LAYOUT_TYPE_MODERN]: lazy(() => import('./components/BlankLayout')),
  [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./components/BlankLayout')),
  [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./components/BlankLayout')),
  [LAYOUT_TYPE_DECKED]: lazy(() => import('./components/BlankLayout')),
  [LAYOUT_TYPE_BLANK]: lazy(() => import('./components/BlankLayout')),
};

export type PrivateLayoutProps = {};

export const PrivateLayout: FunctionComponent<PropsWithChildren<PrivateLayoutProps>> = ({ children }) => {
  const layoutType = useAppSelector((state) => state.theme.layout.type);

  useDirection();

  useLocale();

  const AppLayout = useMemo(() => {
    return layouts[layoutType];
  }, [layoutType]);

  return (
    <Suspense
      fallback={
        <div className="flex h-[100vh] flex-auto flex-col">
          <Loading loading={true} />
        </div>
      }
    >
      <AppLayout>{children}</AppLayout>
    </Suspense>
  );
};
