import {
  GetClientsQueryParams,
  GetProductUsageQueryParams,
  GetStockContentsQueryParams,
  GetWorkOrdersQueryParams,
  RTKQueryCacheLifecycleApi,
  SearchProductsPayload,
  mobystockApi,
} from 'src/services';
import { WorkOrder } from 'src/types';
import { setupEventSource } from 'src/utils';

export const setupGetClientsCacheLogic = async <T>(
  api: RTKQueryCacheLifecycleApi<T>,
  arg: T extends GetClientsQueryParams ? T : never,
  topics: string[],
) => {
  const { cacheDataLoaded, cacheEntryRemoved, dispatch } = api;

  const eventSource = setupEventSource(topics);

  try {
    await cacheDataLoaded;

    eventSource.onmessage = (_e) => {
      // const data = JSON.parse(e.data);
      // refetch query when mercure event received
      dispatch(mobystockApi.endpoints.getClients.initiate(arg, { forceRefetch: true }));

      //   //   ..or update cached data manually
      //   updateCachedData((draft) => {
      //     // if (!isClient(data) || data.channel !== arg) return;
      //     draft.push(data);
      //   });
    };
    eventSource.onerror = async (e) => {
      console.log('EventSource error: ', e);
      // uncomment this line to close the EventSource on error
      //   eventSource.close();
    };
  } catch {
    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
    // in which case `cacheDataLoaded` will throw
  }

  // cacheEntryRemoved will resolve when the cache subscription is no longer active
  await cacheEntryRemoved;
  // perform cleanup steps once the `cacheEntryRemoved` promise resolves
  eventSource.close();
};

export const setupGetContentsCacheLogic = async <T>(
  api: RTKQueryCacheLifecycleApi<T>,
  arg: T extends GetStockContentsQueryParams ? T : never,
  topics: string[],
) => {
  const { cacheDataLoaded, cacheEntryRemoved, dispatch } = api;
  const eventSource = setupEventSource(topics);
  try {
    await cacheDataLoaded;
    eventSource.onmessage = (_e) => {
      // refetch query when mercure event received
      dispatch(mobystockApi.endpoints.getStockContents.initiate(arg, { forceRefetch: true }));
    };
    eventSource.onerror = async (e) => {
      console.log('EventSource error: ', e);
      // uncomment this line to close the EventSource on error
      //   eventSource.close();
    };
  } catch {
    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
    // in which case `cacheDataLoaded` will throw
  }
  // cacheEntryRemoved will resolve when the cache subscription is no longer active
  await cacheEntryRemoved;
  // perform cleanup steps once the `cacheEntryRemoved` promise resolves
  eventSource.close();
};

const doRefresh = (workorder: WorkOrder) => {
  // in some cases the refresh should not be launched as the event received is one of successive events
  // and only the valid one should trigger the refresh
  if (workorder.Status.SENT == 1 && workorder.Signature) return false;
  return true;
};

export const setupGetWorkOrdersCacheLogic = async <T>(
  api: RTKQueryCacheLifecycleApi<T>,
  arg: T extends GetWorkOrdersQueryParams ? T : never,
  topics: string[],
) => {
  const { cacheDataLoaded, cacheEntryRemoved, dispatch } = api;
  const eventSource = setupEventSource(topics);
  try {
    await cacheDataLoaded;
    eventSource.onmessage = (_e) => {
      const { data: wo } = _e;
      const workorder = JSON.parse(wo) as WorkOrder;
      // refetch query when mercure event received
      doRefresh(workorder) && dispatch(mobystockApi.endpoints.getWorkOrders.initiate(arg, { forceRefetch: true }));
    };
    eventSource.onerror = async (e) => {
      console.log('EventSource error: ', e);
      // uncomment this line to close the EventSource on error
      eventSource.close();
    };
  } catch {
    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
    // in which case `cacheDataLoaded` will throw
  }
  // cacheEntryRemoved will resolve when the cache subscription is no longer active
  await cacheEntryRemoved;
  // perform cleanup steps once the `cacheEntryRemoved` promise resolves
  eventSource.close();
};

export const setupGetProductUsageCacheLogic = async <T>(
  api: RTKQueryCacheLifecycleApi<T>,
  arg: T extends GetProductUsageQueryParams ? T : never,
  topics: string[],
) => {
  const { cacheDataLoaded, cacheEntryRemoved, dispatch } = api;
  const eventSource = setupEventSource(topics);
  try {
    await cacheDataLoaded;
    eventSource.onmessage = (_e) => {
      // refetch query when mercure event received
      dispatch(mobystockApi.endpoints.getProductUsage.initiate(arg, { forceRefetch: true }));
    };
    eventSource.onerror = async (e) => {
      console.log('EventSource error: ', e);
      // uncomment this line to close the EventSource on error
      //   eventSource.close();
    };
  } catch {
    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
    // in which case `cacheDataLoaded` will throw
  }
  // cacheEntryRemoved will resolve when the cache subscription is no longer active
  await cacheEntryRemoved;
  // perform cleanup steps once the `cacheEntryRemoved` promise resolves
  eventSource.close();
};

export const setupSearchProductsCacheLogic = async <T>(
  api: RTKQueryCacheLifecycleApi<T>,
  arg: T extends SearchProductsPayload ? T : never,
  topics: string[],
) => {
  const { cacheDataLoaded, cacheEntryRemoved, dispatch } = api;
  const eventSource = setupEventSource(topics);
  try {
    await cacheDataLoaded;
    eventSource.onmessage = (_e) => {
      // refetch query when mercure event received
      dispatch(mobystockApi.endpoints.searchProducts.initiate(arg, { forceRefetch: true }));
    };
    eventSource.onerror = async (e) => {
      console.log('EventSource error: ', e);
      // uncomment this line to close the EventSource on error
      //   eventSource.close();
    };
  } catch {
    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
    // in which case `cacheDataLoaded` will throw
  }
  // cacheEntryRemoved will resolve when the cache subscription is no longer active
  await cacheEntryRemoved;
  // perform cleanup steps once the `cacheEntryRemoved` promise resolves
  eventSource.close();
};
