import classNames from 'classnames';
import { ReactNode, forwardRef } from 'react';
import { useTabs } from './context';

export type TabListProps = { className?: string; children: ReactNode };

export const TabList = forwardRef<HTMLDivElement, TabListProps>(({ className, children, ...rest }, ref) => {
  const { variant } = useTabs();

  const tabListClass = classNames('tab-list', `tab-list-${variant}`, className);

  return (
    <div role="tablist" className={tabListClass} ref={ref} {...rest}>
      {children}
    </div>
  );
});
