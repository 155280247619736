import { MERCURE_BASE_URL } from 'src/config/env';
import EventSource from 'eventsource';
import { MERCURE_TOKEN_COOKIE } from 'src/config/cookies';

export const setupEventSource = (topics: string[]): EventSource => {
  const url = new URL(MERCURE_BASE_URL);
  for (const topic of topics) {
    url.searchParams.append('topic', topic);
  }
  const mercure_token = localStorage.getItem(MERCURE_TOKEN_COOKIE);
  const eventSourceInitDict = {
    headers: {
      Authorization: {
        toString: function () {
          return 'Bearer ' + mercure_token;
        },
      },
    },
  };
  const eventSource = new EventSource(url.toString(), eventSourceInitDict);
  return eventSource;
};
