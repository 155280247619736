import classNames from 'classnames';
import { ReactNode, forwardRef } from 'react';
import { useConfig } from '../ConfigProvider';
import { useForm } from '../Form/context';
import { Size } from '../utils/constant';
import { InputGroupContextConsumer, InputGroupContextProvider } from './context';

export type InputGroupProps = {
  children: ReactNode;
  className?: string;
  size: Size | null;
};

export const InputGroup = forwardRef<HTMLDivElement, InputGroupProps>((props, ref) => {
  const { children, className, size } = props;

  const { controlSize } = useConfig();
  const formControlSize = useForm()?.size;

  const inputGroupSize = size || formControlSize || controlSize;

  const inputGroupClass = classNames('input-group', className);

  const contextValue = {
    size: inputGroupSize,
  };
  return (
    <InputGroupContextProvider value={contextValue}>
      <InputGroupContextConsumer>
        {() => {
          return (
            <div ref={ref} className={inputGroupClass}>
              {children}
            </div>
          );
        }}
      </InputGroupContextConsumer>
    </InputGroupContextProvider>
  );
});
