import classNames from 'classnames';
import { ReactNode, forwardRef } from 'react';
import { TabsContextProvider } from './context';
import { useControllableState } from '../hooks';

export type TabsProps = {
  value?: string;
  onChange?: (value: string) => void;
  defaultValue: string;
  variant?: 'underline' | 'pill';
  className?: string;
  children: ReactNode;
};

export const Tabs = forwardRef<HTMLDivElement, TabsProps>(
  ({ value: valueProp, onChange, defaultValue, variant = 'underline', className, ...rest }, ref) => {
    const [value, setValue] = useControllableState({
      //@ts-expect-error
      prop: valueProp,
      //@ts-expect-error
      onChange: onChange,
      defaultProp: defaultValue,
    });

    const tabsClass = classNames('tabs', className);

    return (
      <TabsContextProvider
        value={{
          value: value,
          onValueChange: setValue,
          variant,
        }}
      >
        <div className={tabsClass} {...rest} ref={ref} />
      </TabsContextProvider>
    );
  },
);
