import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectOption, SelectProps } from '../ui';

export type StockContentMovementTypeSelectProps = SelectProps & { onChange: (option: SelectOption) => void };

export const StockContentMovementTypeSelect = forwardRef<HTMLDivElement, StockContentMovementTypeSelectProps>(
  ({ size = 'sm', placeholder, onChange, value }, ref) => {
    const { t } = useTranslation();

    const options: SelectOption[] = [
      { label: t('stockcontents.form.adjustment'), value: 'adjustment' },
      { label: t('stockcontents.form.transfer'), value: 'transfer' },
    ];

    return (
      <Select
        ref={ref}
        size={size}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        value={options.find((c) => c.value === value)}
      />
    );
  },
);
