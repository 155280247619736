import { forwardRef } from 'react';
import { useGetClientEquipmentTypesQuery } from 'src/services';
import { Alert, Select, SelectProps } from '../ui';
import { Loading } from './Loading';
import { useFormikContext } from 'formik';

export type ClientEquipmentTypeSelectProps = SelectProps & {};

export const ClientEquipmentTypeSelect = forwardRef<HTMLElement, SelectProps>(
  ({ size = 'sm', field, ...otherProps }, ref) => {
    const { data: equipmentTypesData, isLoading, isError } = useGetClientEquipmentTypesQuery();
    const { setFieldValue } = useFormikContext();

    const onChange = (value: unknown) => {
      setFieldValue('type', value);
    };

    const equipmentTypeOptions = equipmentTypesData?.map((equipType) => {
      return { label: equipType.Name, value: equipType.Name };
    });

    if (isLoading) {
      return <Loading loading={true} />;
    }

    return (
      <>
        <Select
          ref={ref}
          size={size}
          options={equipmentTypeOptions}
          defaultInputValue={field?.value.toString()}
          onChange={onChange}
          {...otherProps}
        />
        {isError && <Alert>Unable to retrieve equipment types.</Alert>}
      </>
    );
  },
);
